import { TabMainDiv } from './styled-tabInterface';
import { Tabs, Tab } from 'react-bootstrap';
import { TechStack, Metadata, Contact } from './tabs';
import { websiteLookupResultTypes } from '../types/websiteLookupResultTypes';

const TabInterface = ({categories, meta_title, description, keywords, ...rest}: websiteLookupResultTypes['websiteDetails']) => {
    return (
        <TabMainDiv>
            <Tabs
                defaultActiveKey="technology_Stack"
                id="uncontrolled-tab"
                className="mb-3"
                >
                <Tab eventKey="technology_Stack" title="Technology Stack">
                    <TechStack categories={categories} />
                </Tab>
                <Tab eventKey="metadata" title="Metadata">
                    <Metadata title={meta_title} description={description} keywords={keywords} />
                </Tab>
                <Tab eventKey="contact_Details" title="Contact Details">
                    <Contact {...rest} />
                </Tab>
            </Tabs>
        </TabMainDiv>
    )
}

export default TabInterface