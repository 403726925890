import styled from "styled-components";

export const UpgradeDiv = styled.section`
    box-sizing: border-box;
    width: 100%;
    max-width: 877px;
    display: flex;
    flex-direction: column;
    padding: 26px 34px;
    justify-content: start;
    align-items: baseline;
    background-color: #DAE6F8;
    border-radius: 10px;
    margin: 0 auto 30px;
    font-family: Inter;

    @media only screen and (max-width: 800px){
        padding: 26px 20px;
    }

    & h3{
        margin: 12px 0 5px 0;
        color: #000000;
        font-family: inherit;
        font-size: 22px;
        font-weight: 500;
    }

    & div{
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 18px;
        font-family: Nunito;
        color: #000000; 

        & svg{
            margin-right: 20px;
            min-width: 17px;
            width: 17px;
        }
    }

  
`