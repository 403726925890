import React, { createContext, useEffect, useState } from 'react';
import axios from "../services/commonHttp";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import Cookies from 'universal-cookie';

interface AuthContextContextType {
    isLogin: boolean;
    user: any;
    logout: () => void;
}

interface AuthContextProviderProps {
    children: React.ReactNode;
}


const AuthContext = createContext<AuthContextContextType>({
    isLogin: false,
    user: null,
    logout: () => Promise.resolve()
});

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const cookies = new Cookies();
    const storedEmail = cookies.get('user_email');
    var defaultIsLogin = false
    var defaultUser = null

    if (storedEmail) {
        defaultIsLogin = true
        defaultUser = {email: storedEmail};
    }

    const [isLogin, setIsLogin] = useState<boolean>(defaultIsLogin);
    const [user, setUser] = useState<any>(defaultUser);

    const cleanUser = () => {
        setUser(null);
        localStorage.removeItem('accessToken');
    }
    const checkLogin = async () => {
        await axios({
            method: "POST",
            url: `/user/checklogin`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                console.log('response', response)
                if (response.data.success === true ) {
                    setIsLogin(true)
                    setUser(response.data.user)
                    localStorage.setItem('accessToken', response.data.token);
                }
                else {
                    setIsLogin(false)
                    cleanUser()
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLogin(false)
                cleanUser()
            })
            .finally(() => {

            });
    };

    useEffect(() => {
        checkLogin();
    }, []);

    const logout = async (): Promise<void> => {
        console.log('logout')
        const accessToken = localStorage.getItem('accessToken');
        await axios({
            method: "POST",
            url: `/user/logout`,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}`
            },
            withCredentials: true,
        }).then((response) => {
            console.log('response', response.data)
            if (response.data.success === true ) {
                setIsLogin(false)
                cleanUser()
            }
        }).catch((error) => {

        });
    };

    return (
        <AuthContext.Provider value={{ isLogin, user, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
