import styled from 'styled-components';

export const TabMainDiv = styled.section`
    padding: 0;
    margin-bottom: 30px;

    & ul{
        margin: 0 !important;
    }

    & button{
        font-size: 18px;

        @media (max-width: 420px) {
            font-size: 14px;
            padding: 12px;
        }
    }
`