import { useState, useEffect } from 'react';
import { Main } from './styled-articleContent';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import { useParams } from 'react-router-dom';

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Articles', path: '/articles'},
]

const ArticleContent = () => {
  const {slug} = useParams();
  const [articleList, setArticleList] = useState<string>();
  const [articleTitle, setArticleTitle] = useState<string>();
  const [articleContent, setArticleContent] = useState<string>();
  const currentURL = window.location.href + "/";

  const fetchArticleList = () => {
    fetch(process.env.REACT_APP_ARTICLES_LIST_URL as string)
    .then(response => {
      return response.json();
    }).then(data => {
      setArticleList(data);
      if (data !== undefined) {
        getArticleContent(data);
      }
    }).catch((e: Error) => {
      console.log(e.message);
    });
  }

  const fetchArticleData = (contentDataURL: any) => {
    fetch(contentDataURL as string)
    .then(response => {
      return response.json();
    }).then(data => {
      if (data !== undefined) {
        setArticleContent(data['content']);
        setArticleTitle(data['title']);
      }
    }).catch((e: Error) => {
      console.log(e.message);
    });
  }

  function getArticleContent(data: any) {
    data!.forEach((element: any) => {
        if (element['slug'] === slug) {
            fetchArticleData(element['content-data']);
        }
    });
  }

  const ArticleContent = (props: any) => {
    return (
        <div>
            <h1>{ articleTitle }</h1>
            <div className='article-content' dangerouslySetInnerHTML={ { __html: props.data } }>
            </div>
        </div>
    );
  }  

  useEffect(() => {
    fetchArticleList();
},[])


  return ( 
    <Main>
        <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <div className='content-container'>
                <ArticleContent data={articleContent} />
            </div>
        </div>
    </Main>
  )
}

export default ArticleContent