import React from 'react'

function Loader() {
  return (
    <>
        <div id="preloader" style={{'position':'relative','margin':'auto','width':'auto'}}>
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    </>
  )
}

export default Loader