import { UpgradeDiv } from './styled-upgrade'
import {Button} from "react-bootstrap";

const Check = () => <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M17.7805 0.468876C17.8503 0.538544 17.9057 0.621308 17.9436 0.712425C17.9814 0.803543 18.0008 0.901225 18.0008 0.999876C18.0008 1.09853 17.9814 1.19621 17.9436 1.28733C17.9057 1.37844 17.8503 1.46121 17.7805 1.53088L7.28048 12.0309C7.21081 12.1007 7.12805 12.1561 7.03693 12.1939C6.94582 12.2318 6.84813 12.2512 6.74948 12.2512C6.65083 12.2512 6.55315 12.2318 6.46203 12.1939C6.37091 12.1561 6.28815 12.1007 6.21848 12.0309L0.968482 6.78088C0.827652 6.64005 0.748535 6.44904 0.748535 6.24988C0.748535 6.05071 0.827652 5.85971 0.968482 5.71888C1.10931 5.57805 1.30032 5.49893 1.49948 5.49893C1.69865 5.49893 1.88965 5.57805 2.03048 5.71888L6.74948 10.4394L16.7185 0.468876C16.7882 0.399031 16.8709 0.343617 16.962 0.305807C17.0532 0.267997 17.1508 0.248535 17.2495 0.248535C17.3481 0.248535 17.4458 0.267997 17.5369 0.305807C17.628 0.343617 17.7108 0.399031 17.7805 0.468876Z" fill="black"/>
</svg>

const list = [
  'Include contact details',
  'Filter websites by country',
  'Obtain results preferentially when the server is under high load'
]

const Upgrade = () => {
  return (
    <UpgradeDiv>
        <h3>Upgrade to Premium</h3>
        {list.map((l, i) => (
          <div key={`check-${i}`}><Check />{l}</div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`} >
            <Button variant='primary' size='lg'>Upgrade Now</Button>
        </a>
        </div>
    </UpgradeDiv>
  )
}

export default Upgrade