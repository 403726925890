import React from 'react';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { TechnologyProfileCompact } from '../../components/technologyProfileCompact';

const TechnologyProfileCompactView: React.FC = () => {
  return (
    <div id='wrapper'>
        <Header />
        <TechnologyProfileCompact />
        <Footer />
    </div>
  )
}

export default TechnologyProfileCompactView