import React, { useState } from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Header } from "../header";
import Loader from "../../dashboard-components/common/Loader";
import { Footer } from "../footer";
ChartJS.register(ArcElement, Tooltip, Legend);

 const CategoryOverview = (props: any) => {
  const { technologiesData, chartViewData, isLoading, trackedWebsitesCount } = props;

  const [chartData, setChartData] = useState([]);
  const [chartLables, setChartLables] = useState([]);
  const data = {
    labels: chartLables,
    datasets: [
      {
        label: "Market Share Percentage",
        data: chartData,
        backgroundColor: [
          "#4169E1",
          "#FF0000",
          "#FFA500",
          "#2E8B57",
          "#8B008B",
          "#87CEFA",
          "#FF1493",
          "#32CD32",
          "#B22222",
          "#4682B4",
        ],
        borderColor: [
          "#4169E1",
          "#FF0000",
          "#FFA500",
          "#2E8B57",
          "#8B008B",
          "#87CEFA",
          "#FF1493",
          "#32CD32",
          "#B22222",
          "#4682B4",
        ],
        borderWidth: 1,
      },
    ],
  };

  var options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: true,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  useEffect(() => {
    let chartArrya: any = [];
    let chartLable: any = [];

    for (let i = 0; i < technologiesData?.length; i++) {
      const element = technologiesData[i];
      if (i < 10) {
        chartArrya.push(Number(element.market_share_percentage) * 100);
        chartLable.push(element.name);
      }
    }
    setChartLables(chartLable);
    setChartData(chartArrya);
  }, [technologiesData]);

  return (
    <div id="wrapper">
      <Header />
      <div
        className="no-bottom no-top"
        id="content"
      >
        <div id="top"></div>
        {isLoading === false ? (
          <section id="subheader" className="pb-0">
            <div className="center-y relative" data-scroll-speed="4">
              <div className="container">
                <div>
                  <h1>{chartViewData.name}</h1>
                  <p className="chart-lable">
                    Top tacked technologies
                  </p>
                </div>
                <div className="row">

                    {trackedWebsitesCount > 0 ? (
                        <div>
                            <div className="col-md-12">
                                <div className="clearfix myChart" id="myChart">
                                    {isLoading === false ? (
                                        <Doughnut data={data} options={options} />
                                    ) : (
                                        <Loader />
                                    )}
                                </div>
                            </div>
                            <h3 className="text-center mt-5">
                                <strong>{chartViewData.name} technologies market share</strong>
                            </h3>
                        </div>
                    ) : (
                        <h3 className="text-center p-5">
                            <strong>We are not tracking any websites for {chartViewData.name} category</strong>
                        </h3>
                    )}
                </div>
              </div>
            </div>

              {technologiesData.length > 0 && (
                  <div>
                      <section>
                          <div className="card-contant container p-5">
                              <h6 className="card-heading">Upgrade to Premium</h6>
                              <p className="card-text mb-0">
                                  Reach out to {chartViewData.name} users
                              </p>
                              <p className="card-text">
                                  Create a list of websites using {chartViewData.name} with company and contact
                                  details.
                              </p>
                              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                  <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`} >
                                      <Button variant='primary' size='lg'>Upgrade Now</Button>
                                  </a>
                              </div>
                          </div>
                      </section>
                      <div className="container">
                          <Table responsive="md">
                              <thead>
                              <tr>
                                  <th>#</th>
                                  <th style={{ width: "40%" }}>Technologey</th>
                                  <th style={{ width: "20%" }}>Website tracked</th>
                                  <th style={{ width: "40%" }}>Market share</th>
                              </tr>
                              </thead>
                              {technologiesData.map((item: any, index: any) => {
                                  return (
                                      <React.Fragment key={"indexChart" + index}>
                                          <tbody>
                                          <tr>
                                              <td className="border-0 align-middle">{index + 1}</td>
                                              <td className="d-flex align-items-center border-0">
                                                  <div className="image-show">
                                                      <a href={`/technology/compact/${item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                          <img
                                                              src={"/images/icons/" + item.icon}
                                                              style={{
                                                                  height: "20px",
                                                                  width: "20px",
                                                              }}
                                                          />
                                                      </a>
                                                  </div>
                                                  <a href={`/technology/compact/${item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                      {item.name}
                                                  </a>
                                              </td>
                                              <td className="border-0">{item.websites_count}</td>
                                              <td className="border-0">
                                                  <ProgressBar

                                                      now={Number(item.market_share_percentage) * 100}
                                                      label={`${(
                                                          Number(item.market_share_percentage) * 100
                                                      ).toFixed(2)}%`}
                                                  />
                                              </td>
                                          </tr>
                                          </tbody>
                                      </React.Fragment>
                                  );
                              })}
                          </Table>
                      </div>
                  </div>
              )}


          </section>
        ) : (
          <div className="lodder-div" style={{ top: '20%'}} >
            <Loader />
          </div>
        )}
      </div>

      <Footer technology={technologiesData.length} />
    </div>
  );
};
export default CategoryOverview