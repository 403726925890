// Import necessary dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Result {
    "description": string;
    "icon": string;
    "id": number;
    "name": string;
    "website": string;
}

// Define the state interface
interface TechnologiesDataState {
    result: Result[];
}

// Define the Result interface
interface Result {
    id: number;
    name: string;
    description: string;
    // Add other properties as needed
}

// Define the initial state
const initialState: TechnologiesDataState = {
    result: []
};

// Create the Redux slice
export const technologiesDataSlice = createSlice({
    name: 'technologiesData',
    initialState,
    reducers: {
        setResult: (state, action: PayloadAction<Result[]>) => {
            state.result = action.payload;
        }
    }
});

// Export the action and reducer
export const { setResult } = technologiesDataSlice.actions;
export default technologiesDataSlice.reducer;
