import { Footer } from '../../components/footer';
import { Articles } from '../../components/Articles';

const ArticlesView = () => {
    return (
        <div id='wrapper'>
            <Articles />
            <Footer />
        </div>  
    )
}

export default ArticlesView;