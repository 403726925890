import { Footer } from '../../components/footer';
import KeywordBasedListsFeaturePage from '../../components/ProductFeature/KeywordBasedListsFeaturePage';

const KeywordBasedListsFeaturePageView = () => {
    return (
        <div id='wrapper'>
            <KeywordBasedListsFeaturePage />
            <Footer />
        </div>  
    )
}

export default KeywordBasedListsFeaturePageView;