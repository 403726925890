import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import { Footer } from '../../../components/footer'
import { Header } from '../../../components/header'
import { Link } from 'react-router-dom'
import { Autocomplete } from '../../../components/Autocomplete';
import {Button} from "react-bootstrap";

function Home() {
    return (
        <>
            <div id="wrapper">
                <div className="no-bottom no-top" id="content">
                    <div id="top"></div>
                    <section 
                        // className="no-top no-bottom vertical-center text-light"
                        className="no-top no-bottom text-light text-center"
                        //className="full-height no-top no-bottom vertical-center text-light" 
                        >
                        <div className="container ">
                            <div className="row align-items-center">
                                <div className="col-lg-6 fadeInRight m-auto" data-wow-delay=".5s">
                                    <h1 style={{ marginTop: "8rem", color: "#35404e" }} >Website Technology Profiler</h1>
                                    <p className="lead">Discover what a website is built with. Detect and identify website technology. Unravel web tech stack intricacies. Explore technographics data, lead generation and marketing secrets from your competitor's websites.</p>
                                    <div className="spacer-20"></div>
                                    <Autocomplete />
                                    {/*<input type="text" className="form-control mr-2" placeholder='Website URL, Technologies' name="emailName" id="" style={{ borderRadius: '10px', marginBottom: '10rem' }} />
                                    {<a href="#"><img src="images/misc/download-appstore.png" className="img-fluid"/></a>&nbsp;
                                    <a href="#"><img src="images/misc/download-playstore.png" className="img-fluid"/></a> */}
                                    <div className="mb-sm-30"></div>
                                </div>
                                <div className="spacer-single"></div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container" style={{ 'backgroundSize': 'cover' }}>
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3">
                                    <div className="text-center">
                                        <h2><span className="uptitle id-color">Our Services</span>How does it work?</h2>
                                        <div className="spacer-20"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb40 wow fadeInUp" data-wow-delay="0s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color i-circle fa fa-laptop"></i>
                                        <div className="text">
                                            <h4 style={{ height: "60px" }}>Website Technology Analysis</h4>
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.
                                        </div>
                                        <i className="wm fa fa-laptop"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".25s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-2 i-circle fa fa-paper-plane-o"></i>
                                        <div className="text">
                                            <h4 style={{ height: "60px" }}>Technologies Database</h4>
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.
                                        </div>
                                        <i className="wm fa fa-paper-plane-o"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".5s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-3 i-circle fa fa-line-chart"></i>
                                        <div className="text">
                                            <h4 style={{ height: "60px" }}>Competitor analysis</h4>
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.
                                        </div>
                                        <i className="wm i-circle fa fa-line-chart"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 wow fadeInUp" data-wow-delay="0s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color i-circle fa fa-object-group"></i>
                                        <div className="text">
                                            <h4 style={{ height: "60px" }}>Report Export</h4>
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.
                                        </div>
                                        <i className="wm fa fa-object-group"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".25s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-2 i-circle fa fa-hdd-o"></i>
                                        <div className="text">
                                            <h4 style={{ height: "60px" }}>Advanced Search</h4>
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.
                                        </div>
                                        <i className="wm fa fa-hdd-o"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".5s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-3 i-circle fa fa-comments-o"></i>
                                        <div className="text">
                                            <h4 style={{ height: "60px" }}>Keyword & Catalog</h4>
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.
                                        </div>
                                        <i className="wm i-circle fa fa-comments-o"></i>
                                    </div>
                                </div>
                                {/* <div className="col-md-12 text-center">
                                    <a className="btn-custom" href="#">More Features</a>
                                </div> */}
                            </div>
                        </div>
                    </section>
                    {/* <h2>
                                        Data security with<br />
                                        <span className="id-color">256-bit</span> encryption
                                    </h2> */}

                    <section id="section-banner" className="no-top">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 d-none d-lg-block d-xl-block text-center wow fadeInRight" data-wow-delay="0s">
                                    <img className="relative img-fluid" src="images/misc/builtwith.png" alt="" />
                                </div>

                                <div className="col-lg-5 offset-md-1 wow fadeInLeft" data-wow-delay="0s">
                                    <h2>Find out what websites are built with </h2>
                                    <p>
                                        Instantly reveal the technology stack of any website, such as CMS, ecommerce platform or payment processor, as well as company and contact details.
                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                    <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>

                                <div className="spacer-double"></div>
                            </div>
                            <div className="row align-items-center" style={{ 'backgroundSize': 'cover' }}>
                                <div className="col-md-6" style={{ 'backgroundSize': 'cover' }}>
                                    <h2>Create lists of websites and contacts</h2>
                                    <p>Find prospects by the technologies they use. Lead lists contain websites, company and contact details, social media profiles and more. Create and export custom reports for any web technology or keyword based on industry, website traffic and location.

                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>

                                <div className="col-md-5  offset-md-1" style={{ 'backgroundSize': 'cover' }}>
                                    <img src="images/misc/lead-generation.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6 d-none d-lg-block d-xl-block text-center wow fadeInRight" data-wow-delay="0s">
                                    <img className="relative img-fluid" src="images/misc/competitor-analysis.png" alt="" />
                                </div>

                                <div className="col-lg-5 offset-md-1 wow fadeInLeft" data-wow-delay="0s">
                                    <h2>Monitor your competitors' websites</h2>
                                    <p>
                                        Get notified when a website's technology stack changes. Be the first to know when a competitor moves to a new CMS, ecommerce platform or any other technology.
                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>

                                <div className="spacer-double"></div>
                            </div>
                            <div className="row align-items-center" style={{ 'backgroundSize': 'cover' }}>
                                <div className="col-md-6" style={{ 'backgroundSize': 'cover' }}>
                                    <h2>Automate technology lookups</h2>
                                    <p>The Webrofiler APIs provide instant access to website technology stacks, company and contact details, social media profiles, email verification and more. Track your prospects' technology choices, examine large numbers of websites or enrich your own datasets with technographic insights.

                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>

                                <div className="col-md-5  offset-md-1" style={{ 'backgroundSize': 'cover' }}>
                                    <img src="images/misc/website-profiler.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6 d-none d-lg-block d-xl-block text-center wow fadeInRight" data-wow-delay="0s">
                                    <img className="relative img-fluid" src="images/misc/web-technology.png" alt="" />
                                </div>

                                <div className="col-lg-5 offset-md-1 wow fadeInLeft" data-wow-delay="0s">
                                    <h2> Market Share</h2>
                                    <p>TGet advanced technology market share information and country based analytics for all web technologies.

                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>                                </div>

                                <div className="spacer-double"></div>
                            </div>

                        </div>
                    </section>

                    <section id="section-testimonial">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <h2><span className="uptitle id-color">Testimonial</span>What They Says</h2>
                                        <div className="spacer-20"></div>
                                    </div>

                                    {/* <div className="owl-carousel owl-theme wow fadeInUp" id="testimonial-carousel"> */}
                                    <OwlCarousel className='owl-theme' loop margin={10} nav>
                                        <div className="item">
                                            <div className="de_testi opt-2">
                                                <blockquote>
                                                    <p>Great support, like i have never seen before. Thanks to the support team, they are very helpfull. This company provide customers great solution, that makes them best.</p>
                                                    <div className="de_testi_by"><img alt="" className="rounded-circle" src="images/people/1.jpg" /> <span>John, Pixar Studio</span></div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi opt-2">
                                                <blockquote>
                                                    <p>Great support, like i have never seen before. Thanks to the support team, they are very helpfull. This company provide customers great solution, that makes them best.</p>
                                                    <div className="de_testi_by"><img alt="" className="rounded-circle" src="images/people/2.jpg" /> <span>Sarah, Microsoft</span></div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi opt-2">
                                                <blockquote>
                                                    <p>Great support, like i have never seen before. Thanks to the support team, they are very helpfull. This company provide customers great solution, that makes them best.</p>
                                                    <div className="de_testi_by"><img alt="" className="rounded-circle" src="images/people/3.jpg" /> <span>Michael, Apple</span></div>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="section-fun-facts" className="pt60 pb60 text-light bg-color-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="15425" data-speed="3000">0</h3>
                                        <span>Website Powered</span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".25s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="8745" data-speed="3000">0</h3>
                                        <span>Clients Supported</span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="235" data-speed="3000">0</h3>
                                        <span>Awards Winning</span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".75s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="15" data-speed="3000">0</h3>
                                        <span>Years Experience</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Home