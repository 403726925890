import { useState, useEffect } from 'react';
import { Main } from './styled-articles';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import Upgrade from '../websiteLookupResult/upgrade/Upgrade';
import Accordion from 'react-bootstrap/Accordion';
import {Form} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Articles', path: '/articles'},
]

const Articles = () => {
  const [articleList, setArticleList] = useState<string>();
  const currentURL = window.location.href + "/";

  const fetchArticleList = () => {
      fetch(process.env.REACT_APP_ARTICLES_LIST_URL as string)
    .then(response => {
      return response.json();
    }).then(data => {
      setArticleList(data);
    }).catch((e: Error) => {
      console.log(e.message);
    });
  }

  const navigate = useNavigate()

  function viewArticleContent(value: string) {
    // if (value.length)
      // navigate(value);
  }

  const ArticleList = (props: any) => {
    return (
        <div>
        {
            props.data && props.data.map ((article: any) =>(
              <a key={ article.id } href={ currentURL + article.slug } className='text-decoration-none text--primary' onClick={() => viewArticleContent( currentURL + article.slug )}>
                <div>
                  <h2 className='text-h2 mt-8 mb-2 text-decoration-underline'>
                    { article.title }
                  </h2>
                  <p>
                  { article.excerpt }
                  </p>
                </div>
              </a>
            ))
          }
        </div>
    );
  }  

  useEffect(() => {
    fetchArticleList()
  },[])

  const showContent = (id: any) => {
    
  }

  return ( 
    <Main>
        <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <div className='content-container'>
                <h1>Articles</h1>
                <div>
                  <ArticleList data={articleList} />
                </div>
            </div>
        </div>
    </Main>
  )
}

export default Articles