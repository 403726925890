import React, {useContext, useEffect, useState} from 'react'
// import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Link, useLocation } from 'react-router-dom'
import { Navbar, Nav, Container, Button, Dropdown, NavDropdown, Spinner, PopoverBody, OverlayTrigger } from 'react-bootstrap';
import StyledLink from './styled-header';
import authContext from "../../contexts/AuthContext";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');
function Header() {

    const { isLogin, user, logout } = useContext(authContext);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleLogout = async () => {
        setIsLoading(true);
        await logout();
        setIsLoading(false);
    }

    const onLogin = () => {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL + '/auth/login/basic';
    };
    const onCreateAccount = () => {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL + '/auth/register/basic';
    }

    const onDashboard = () => {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL + '/dashboards/home';

    }

    return (
        <div>
        <Navbar bg="white" expand="lg" fixed="top">
            <Navbar.Brand style={{ marginLeft: "20px" }} href="/">
                <img
                    src={'/images/logo5.png'}
                    alt="Logo"
                    width="120"
                    className="d-inline-block align-top"
                />
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" style={{ marginRight: "20px" }}>
                <Nav className="mx-auto">
                    <NavDropdown title="Products" id="basic-nav-dropdown" style={{ marginLeft: "20px" }}>
                        <NavDropdown.Item>
                            <StyledLink to="/websitelookup" >Web Technology Lookup</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/technologies">Browse Technologies</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/feature/detailed-website-profiles">Detailed Website Profiles</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/feature/competitor-comparison">Competitor Comparison</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/feature/technology-market-share">Technology Market Share</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/feature/keyword-based-lists">Keyword Based Lists</StyledLink>
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/pricing" style={{ marginLeft: "20px" }}>Pricing</Nav.Link>
                    <NavDropdown title="Resources" id="basic-nav-dropdown" style={{ marginLeft: "20px" }} >
                        <NavDropdown.Item>
                            <StyledLink to="/technologies">Browse Technologies</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/articles">Articles</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/faqs">FAQs</StyledLink>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                    {isLogin ? (
                            <Nav className="ml-auto">
                                    {!isLoading ? (
                                        <NavDropdown title={user?.email} id="basic-nav-dropdown" style={{ marginLeft: "20px" }}>
                                            <NavDropdown.Item>
                                                <StyledLink onClick={onDashboard}>
                                                    Dashboard
                                                </StyledLink>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item>
                                                <StyledLink onClick={handleLogout}>Logout</StyledLink>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    ) : (
                                        <div>
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    )}
                            </Nav>
                    ) : (
                            <div>
                                <Nav className="ml-auto">

                                <Button variant="outline-secondary" onClick={onLogin}>Sign in</Button>{' '}
                                <Button variant="primary" style={{ marginLeft: "20px" }} onClick={onCreateAccount}>Sign Up</Button>{' '}
                                </Nav>
                            </div>
                        )}
            </Navbar.Collapse>
        </Navbar>
        </div>
    )
}

export default Header