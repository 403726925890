import React, { useRef } from 'react'
// import OwlCarousel from 'react-owl-carousel'
// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel/dist/assets/owl.theme.default.css'

import './styles/plugins/perfect-scrollbar/css/perfect-scrollbar.css'
import './styles/plugins/metismenu/css/metisMenu.min.css'
import './styles/css/pace.min.css'
import './styles/css/app.css'
import './styles/css/icons.css'
import './styles/css/dark-theme.css'
import './styles/css/semi-dark.css'
import './styles/css/header-colors.css'

import Footer from '../../../dashboard-components/common/Footer'
import Header from '../../../dashboard-components/common/Header'
import SideBar from '../../../dashboard-components/common/SideBar'
import { Link, Outlet } from 'react-router-dom'

function DashboardLayout() {
    const wrapperRef = useRef(null)
    return (
        <>
            <div ref={wrapperRef} className='wrapper' id="wrapper">
                <SideBar wrapperRef={wrapperRef} />
                <Header />
                <Outlet />
                <Footer />
            </div>
        </>
    )
}

export default DashboardLayout