import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div className="overlay toggle-icon"></div>
            {/* <!--end overlay-->
            <!--Start Back To Top Button--> */}
            <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
            {/* <!--End Back To Top Button--> */}
            <footer className="page-footer">
                <p className="mb-0">Copyright © {new Date().getFullYear()}. All right reserved.</p>
            </footer>
        </>
    )
}

export default Footer