import { Footer } from '../../components/footer';
import CompetitorComparisonFeaturePage from '../../components/ProductFeature/CompetitorComparisonFeaturePage';

const CompetitorComparisonFeaturePageView = () => {
    return (
        <div id='wrapper'>
            <CompetitorComparisonFeaturePage />
            <Footer />
        </div>  
    )
}

export default CompetitorComparisonFeaturePageView;