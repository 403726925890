import axios from '../../../services/commonHttp';

export const getWebsiteResult = async (url:string) => axios({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url, 
})

export const requestEmailNotification = async (url:string, params:{domain: string, email:string}) => axios({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    url, 
    params
})
