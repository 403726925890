import React, { useEffect, useState } from "react"; 
import { useParams } from "react-router-dom";
import { CategoryOverview } from "../../components/categoryOverview";



import axios from "../../services/commonHttp";

const ChartView = () => {
  const [technologiesData, setTechnologiesData] = useState<any>([]);
  const [chartViewData, setChartViewData] = useState<any>({});
  const [trackedWebsitesCount, setTrackedWebsitesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {slug} = useParams() 
 

  
  const fetchApi = async () => {
    setIsLoading(true)
    await axios({
      method: "GET",
      url: `/category_summary/${slug}`,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setChartViewData(response.data);
        setTechnologiesData(response.data.technologies);
        setTrackedWebsitesCount(response.data.tracked_websites_count)
      })
      .catch((error) => {
        console.log(error);
      })
        .finally(() => {
            setIsLoading(false);
        });
  };

  useEffect(() => {
    
    
    fetchApi();
  }, [slug]);
  return (
    <div id="wrapper">
      <CategoryOverview
        technologiesData={technologiesData}
        chartViewData={chartViewData}
        isLoading={isLoading}
        trackedWebsitesCount={trackedWebsitesCount}
      />
    </div>
  );
};

export default ChartView;
