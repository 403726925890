import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { RootState } from '../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import { loading, website, error, emailRequest } from '../../features/websiteData/websiteDataSlice';
import { getWebsiteResult, requestEmailNotification } from './utils/api';
import { Main } from './styled-websiteLookup';
import Loader from '../../dashboard-components/common/Loader'
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import { TabInterface } from './tabInterface';
import Upgrade from './upgrade/Upgrade';
import AlertCreator from './alerts/AlertCreator';
import EmailRequest from './inputs/email/EmailRequest';
import emailValidation from './utils/emailValidation';

const WebsiteLookupResult = () => {

  const {websiteName} = useParams();
  const { websiteData } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState<{msg: null | string}>({msg: null})
  const emailHandler = async (email: string) => {
    setEmailError(prev => ({msg: null}))
    if(emailValidation(email)){
      await requestEmailNotification(`/website_analyze/set_notification`, {domain: websiteName!, email})
        .then(res => {
          if(res.data.success) dispatch(emailRequest({error: res.data.error, name: websiteName, email}))
          else setEmailError({msg: 'Fail to add your email'})
        })
        .catch(error => console.log(error))
    }
    else setEmailError({msg: 'Invalid Email please Enter the correct one!'})
  }

  useEffect(()=>{
    async function getData(){
      if(websiteData.name !== websiteName){
        if(websiteData.loading === false) dispatch(loading(true))
        await getWebsiteResult(`/website_analyze?url=${websiteName}`)
          .then(res => {
            if(res.data.success === false) dispatch(error({error: res.data.error, name: websiteName}))
            else dispatch(website({websiteDetails: res.data.website, name: websiteName}))
          })
          .catch(error => console.log(error))
      }
    }
    if(websiteName) getData();
  }, [websiteName])

  return ( 
    <Main>
      {
        websiteData.loading
        ?
        <div className='loading'><span className='title'>Website Anylyzing...</span><Loader /></div>
        :
         websiteData.website
        ?
        <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
          <RouteCreator routes={websiteData.routes} />
          <h1>{websiteName}</h1>
          {typeof websiteData.website === 'object' &&
              ( websiteData.website.status !== 'done' ?
                <AlertCreator message={'We are starting to analyze the website, please wait a few minutes'} variant='primary' />
              :
                      <div>
              <TabInterface
            categories={websiteData.website.categories}
            meta_title={websiteData.website.meta_title}
            description={websiteData.website.description}
            keywords={websiteData.website.keywords}
            company_name={websiteData.website.company_name}
            emails={websiteData.website.emails}
            phones={websiteData.website.phones}
            facebook={websiteData.website.facebook}
            instagram={websiteData.website.instagram}
            linkedin={websiteData.website.linkedin}
            pinterest={websiteData.website.pinterest}
            tiktok={websiteData.website.tiktok}
            twitter={websiteData.website.twitter}
            youtube={websiteData.website.youtube}
          />
                        <Upgrade />
                      </div>
              )}

        </div>
        :
        websiteData.error
        ?
        <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
          <RouteCreator routes={websiteData.routes} />
          <h1>{websiteName}</h1>
          {
            websiteData.error === 'InvalidWebsite' ? <AlertCreator message={'The website is unable to access'} variant='danger' /> :
            websiteData.error === 'AddToQueue' ? <AlertCreator message={'We are starting to analyze the website, and we\'ll email you when it\'s ready.'} variant='primary' /> :
            websiteData.error === 'Invalid URL' ? <AlertCreator message={'Invalid url.'} variant='primary' /> :
            <>
              <AlertCreator message={'We are starting to analyze the website, and we\'ll email you when it\'s ready.'} variant='primary' />
              <EmailRequest email={websiteData.email} error={emailError} emailHandler={emailHandler} />
            </>
          }
        </div>
        : ''
      }
    </Main>
  )
}

export default WebsiteLookupResult