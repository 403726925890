import React, { useState, useEffect } from 'react';

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import { Footer } from '../../../components/footer'
import { Header } from '../../../components/header'
import { Link } from 'react-router-dom'
import axios from "../../../services/commonHttp";
import Loader from '../../../dashboard-components/common/Loader';
import { setResult } from '../../../features/technologiesData/technologiesDataSlice';
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "../../../app/store";
// import { TechnologyDataType } from "../../../features/types/AppTypes";

interface Result {
    "description": string;
    "icon": string;
    "id": number;
    "name": string;
    "website": string;
}
interface Category {
    "id": number,
    "name": string,
    "technologies_count": number,
    "pivot": {
        "group_id": number,
        "category_id": number
    }
}
interface CatResult {
    "id": number;
    "name": string;
    "categories": Category[]
};


function Technology() {
    const dispatch = useDispatch();
    // const [result, setResult] = React.useState<Result[]>([]);
    const result = useSelector((state: RootState) => state.technologyData.result);
    const [catResult, setCatResult] = React.useState<CatResult[]>([]);
    const [catFilResult, setCatFilResult] = React.useState<CatResult[]>([]);
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(true);
    const [customLoading, setCustomLoading] = useState(false);
    const [isclose, setIsClose] = useState(false);

    useEffect(() => {
        console.log('result', result.length)
        if(result.length == 0) {
            popularList()
        }
        else {
            setLoading(false)
        }
        // categoryList()
    }, []);
    const popularList = () => {
        console.log('loading popularList')
        axios({
            method: 'get',
            url: `/popular_technologies`,
            headers: { 'Content-Type': 'application/json' },

        }).then((response) => {
            // console.log('response', response.data)
            // setResult(response.data);
            dispatch(setResult(response.data));
            setTimeout(() => {
                setLoading(false)
            }, 3000);
            //         const keysof = Object.keys(response.data);
            // const valuesof = Object.values(response.data);
            // console.log('cSelected', valuesof) 
            // console.log('keysof', keysof) 
            // console.log('result', result) 
        })
            .catch((err) => {
                if (Array.isArray(err.response.data.errors)) {
                    console.log(err.response.data.errors);
                } else {
                    console.log([{ msg: err.response.data }]);
                }
            });
    }
    const categoryList = () => {

        axios({
            method: 'get',
            url: `/all_groups`,
            headers: { 'Content-Type': 'application/json' },

        }).then((response) => {
            // console.log('response', response.data)
            setCatResult(response.data);
            setCatFilResult(response.data);
            setTimeout(() => {
                setLoading(false)
            }, 3000);
            // console.log('result', result) 
        })
            .catch((err) => {
                if (Array.isArray(err.response.data.errors)) {
                    console.log(err.response.data.errors);
                } else {
                    console.log([{ msg: err.response.data }]);
                }
            });
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        // setTimeout(() => {
        //     searchBar()
        // }, 500);
    };

    const searchBar = () => {
        const value = searchInput;
        // const searchBar = (e: React.KeyboardEvent<HTMLInputElement>) => {
        //     const value = (e.target as HTMLInputElement).value;
        setCustomLoading(true)
        // setCatResult(catFilResult)
        console.log('value', value)
        if (value.length > 0) {
            // console.log('value len', value.length)
            setIsClose(true);
            const list = catFilResult.filter((result) => {
                // return result.name.toUpperCase().indexOf(value.toUpperCase()) > -1;
                const rr = result.categories.filter((resultT) => {
                    return resultT.name.toUpperCase().indexOf(value.toUpperCase()) > -1;
                    // .toUpperCase().indexOf(filter) > -1
                });
                // console.log('rr', rr)
                if (rr.length > 0) {
                    result.categories = rr;
                    return result
                } else {
                    return result.name.toUpperCase().indexOf(value.toUpperCase()) > -1;
                }
            });
            setCatResult(() => list)
            // setTimeout(() => {
            //     setCustomLoading(false)

            // }, 1000);
        } else {
            setIsClose(false);
            categoryList()
            // setTimeout(() => {
            //     setCustomLoading(false)
            // }, 1000);
            setCatResult(catFilResult)

        }
    }

    useEffect(() => {
        searchBar()
    }, [searchInput])


    return (
        <>
            <div id="wrapper">
                <Header />
                <div className="no-bottom no-top" id="content" >
                    <div id="top"></div>
                    <section id="subheader" style={{ paddingBottom: 0 }}>
                        <div className="center-y relative" data-scroll-speed="4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <form action='blank.php' className="row" id='form_subscribe' method="post" name="myForm">
                                            <div className="col-md-12">
                                                <h1 className='text-center'>Technologies</h1>
                                                <h3>Browse Technologies and category</h3>
                                                <p className="lead">We tracked 4,094 web technologies across 100 categories. Search for a technology by name or choose a category for information such as market share and traffic.</p>
                                            </div>
                                            <div className="clearfix"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section data-bgimage="url(images/background/9.png) bottom" style={{
                        'backgroundImage': 'url(images/background/9.png)', 'backgroundSize': 'cover',
                        'backgroundPositionX': 'center', 'backgroundPositionY': 'bottom', 'minHeight': '650px !important'
                    }}
                        className="no-top no-bottom text-light">
                        <div className="container" style={{ "backgroundSize": "cover",'marginBottom':'10rem' }}>
                            <div className="row" style={{ "backgroundSize": "cover" }}>
                                <div className="col-md-8 offset-md-2" style={{ "backgroundSize": "cover" }}>
                                    <form action="#" className="row" id="form_subscribe" method="post" name="myForm">
                                        <div className="col-md-12 text-center" style={{ "backgroundSize": "cover" }}>
                                            <h1>Technologies</h1>
                                            <h3>Browse Technologies and category</h3>
                                            <p>We tracked 4,094 web technologies across 100 categories. Search for a technology
                                                by name or choose a category for information such as market share and traffic.</p>
                                        </div>
                                        <div className="clearfix" style={{ "backgroundSize": "cover" }}></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {
                        loading ?
                            // <div style={{paddingBottom: "50vh"}}>
                            <div style={{ height: "50vh", paddingBottom: "40vh" }}>
                                <Loader />
                            </div>
                            :
                            <>
                                <section id="section-highlight" data-bgimage="url(images/background/10.png) top" className='p-0' >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="text-center">
                                                    <h2 style={{ margin: "50px auto" }}>Popular</h2>
                                                    <div className="spacer-20"></div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        result && result.length > 0 && result.map((item) => (
                                                            <div key={item.id} className="col-lg-3 col-md-4 mb30">
                                                                <div className="col-lg-12 col-md-12" style={{ "backgroundSize": "cover" }}>
                                                                    <div className="row">
                                                                        <div className="col-4 " style={{ "height": "50px", "width": "50px", "display": "flex", "justifyContent": "center", "alignItems": "center", "background": "#E9ECEF", "boxShadow": "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "50%", maxWidth: "50px", maxHeight: "50px" }}>
                                                                            <a href={`/technology/compact/${item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                                            <img src={"images/icons/" + item.icon} style={{ 'height': '32px', 'width': '32px' }}/>
                                                                            </a>
                                                                        </div>
                                                                        <div style={{ "fontFamily": "'SF Pro Display'", "fontStyle": "normal", "fontWeight": "700", "fontSize": "16px", "lineHeight": "19px", "color": "#000000", display: "flex", alignItems: "center" }} className="col-7">
                                                                            <a href={`/technology/compact/${item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                                            {item.name}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="section-testimonial" className='p-0' style={{
                                    'color': '#8492a6'
                                    // 'backgroundImage': 'url(images/background/2.png)', 'backgroundSize': 'cover',
                                    // 'backgroundPositionX': 'center', 'backgroundPositionY': 'top'
                                }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/*<div className="text-center">*/}
                                                {/*    <h2>Search</h2>*/}
                                                {/*    <div className="spacer-20"></div>*/}
                                                {/*</div>*/}
                                                {/*<div className="row">*/}
                                                {/*    <div className="col-md-6 m-auto">*/}
                                                {/*        <div className="widget ">*/}
                                                {/*            /!* <h5>Subscribe to receive occasional product update</h5> *!/*/}

                                                {/*            <div className="d-flex ">*/}
                                                {/*                <input type="text"*/}
                                                {/*                    onChange={(e) => handleChange(e)}*/}
                                                {/*                    // onKeyUp={(e) => searchBar(e)}*/}
                                                {/*                    value={searchInput}*/}
                                                {/*                    className="technology-section-search-input" name="emailName" id="inField" />*/}
                                                {/*                {isclose ?*/}
                                                {/*                    <button onClick={(e) => {*/}
                                                {/*                        // (document.getElementById('inField') as HTMLInputElement).value = "";*/}
                                                {/*                        // setCatResult(catFilResult)*/}
                                                {/*                        setSearchInput("")*/}
                                                {/*                        categoryList()*/}
                                                {/*                        setIsClose(false)*/}
                                                {/*                    }} style={{ 'border': 'none', 'background': 'white', 'position': 'absolute', 'right': '19px', 'borderRadius': '10px', 'height': 'inharit', 'top': '10px' }}>*/}
                                                {/*                        <i className="icon_close p-1"></i>*/}
                                                {/*                    </button> : null}*/}
                                                {/*                /!* <Link to="#" onClick={searchBar} className="btn-custom d-flex" style={{ 'borderRadius': '5px', 'backgroundColor': '#f0ebf9', 'color': '#4608ad', 'padding': '5px 15px' }}><i className="icon_search p-1"></i>Search</Link> *!/*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="text-center">
                                                    <h2 className="pt-5">Browser</h2>
                                                </div>
                                                <div className={"pb-5"}>
                                                {
                                                    catResult && catResult.length > 0 && catResult.map((item, index) => (
                                                        <div key={item.id}>
                                                            <hr style={{ 'background': 'solid 1px #E8E8E8' }} />
                                                            <div style={{ "backgroundSize": "cover"}}>
                                                                <h4 className="pt-3 pb-3">
                                                                    <span>
                                                                        {item.name}
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                            <div className="row" style={{ "backgroundSize": "cover" }}>
                                                                {item.categories && item.categories.length > 0 && item.categories.map((it) => (
                                                                    <div className="col-lg-3 col-md-6" key={it.id} style={{ "backgroundSize": "cover" }}>
                                                                        <div className="fb-text" style={{ "backgroundSize": "cover" }}>
                                                                            <p style={{ "fontStyle": "normal", "fontWeight": "350", "fontSize": "18px", "lineHeight": "24px", "color": "#008CFF" }}>
                                                                                <a href={`/catalog/${it.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                                                {it.name}
                                                                                </a>
                                                                                <span style={{ color: "#5F5F5F" }}>
                                                                                    ({it.technologies_count})
                                                                                </span></p>
                                                                        </div>
                                                                    </div>
                                                                    // <div className="col-lg-3 col-md-6 mb5" key={it.id} style={{ "backgroundSize": "cover" }}>
                                                                    //     <div className="fb-text" style={{ "backgroundSize": "cover" }}>
                                                                    //         <h4>{it.name}<span className="uptitle id-color"> ({it.technologies_count})</span></h4>
                                                                    //     </div>
                                                                    // </div>
                                                                ))}
                                                                <div style={{ "backgroundSize": "cover" }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                    }
                </div>
                {
                    loading ?
                        <></>
                        : <Footer />
                }
            </div>
        </>
    )
}

export default Technology