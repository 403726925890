import { createSlice } from '@reduxjs/toolkit';
import { websiteLookupResultTypes } from '../../components/websiteLookupResult/types/websiteLookupResultTypes';
import { RootState } from '../../app/store';

const routesInit = [
    {name: 'Home', path: '/'},
    {name: 'Technology lookup', path: '/websitelookup'},
]

export const initData = {
    loading: true, 
    website: null, 
    error: null, 
    email: null, 
    name: null, 
    routes: routesInit
} as websiteLookupResultTypes['websiteData']

export const websiteDataSlice = createSlice({
    name: 'websiteData',
    initialState: initData,
    reducers: {
        loading: (state, action) => {
            return {...state, loading: action.payload}
        },
        website: (state, action) => {
            return {
                ...initData,
                loading: false,
                routes: [...routesInit, {name: action.payload.websiteDetails.website, path: ''}],
                website: action.payload.websiteDetails,
                name: action.payload.name
            }
        },
        error: (state, action) => {
            const {payload: {error, name}} = action
            if(error === 'AddToQueue' || 'RequireEmail' || 'InvalidWebsite') {
                return {...initData, loading: false, error, name}
            }
            return state;
        },
        emailRequest: (state, action) => {
            const {payload: {email}} = action
            if(typeof email === 'string') return {...state, email}; 
            return state;
        },
    },
})

export const { loading, website, error, emailRequest } = websiteDataSlice.actions;
export const selectData = (state: RootState) => state.websiteData;
export default websiteDataSlice.reducer;