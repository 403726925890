
const validUrl = require('valid-url');
export const extractWebsiteName = (value: string) => {
    try {
        value = value.trim();
        if (!/^https?:\/\//i.test(value)) {
            value = "https://" + value;
        }

        if (!validUrl.isUri(value)) {
            return { error: 'Please enter a valid URL.' };
        }

        const parsedUrl = new URL(value);
        const hostname = parsedUrl.hostname;
        return { website: hostname, error: null };
    } catch (error) {
        console.error('Invalid URL:', error);
        return { error: 'Please enter a valid URL.' };
    }
};

export default extractWebsiteName;
