import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Table, Title, Upgrade } from './index';
import { getTechnology } from './utils/api';
import RouteCreator from './utils/routeCreator';

// mock json data to use on Localhost
// import data from './moch/data.json';

export interface dataInterface {
    id?: number;
    name?: string;
    description?: string;
    icon?: string;
    website?: string;
    websites?: {website: string, traffic: number}[];
    websites_count?: number;
}

export type routesTypes = {name: string; path: string}[]

const TechnologyProfileCompact = () => {

    const routesInit = useMemo(()=>[
        {name: 'Home', path: '/'},
        {name: 'Technologies ', path: '/technologies'},
    ], [])

    let { techId } = useParams(); 

    const [technologyData, setTechnologyData] = useState<dataInterface | null>(null); // All data comes from API
    const [error, setError] = useState({}); // This state is used when we want to show alert message 
    const [routes, setRoutes] = useState<routesTypes>(routesInit); // All routes to show as a router page

    useEffect(()=>{
        async function getData(){
            await getTechnology(`/technology_summary/${techId}`)
                .then(res => {
                    setTechnologyData(res.data);
                    setRoutes(prev => [...routesInit, {name: res.data.name, path: ''}])
                })
                .catch(error => {
                    setError({
                        msg: 'Cannot fetch technology data from server!',
                        details: error
                    })
                })
        }
        getData();
    }, [techId]);

    return (
        <main className='container' style={{paddingTop: '110px'}}>
            {routes?.length ? <RouteCreator routes={routes} /> : null}
            <Title name={technologyData?.name || ''} description={technologyData?.description || ''} icon={technologyData?.icon || ''} website={technologyData?.website || ''} />
            <Table websites={technologyData?.websites || []} title={technologyData?.name || ''} />
            <Upgrade count={technologyData?.websites_count} />
        </main>
    );
}

export default TechnologyProfileCompact