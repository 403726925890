import styled from "styled-components";

export const UpgradeDiv = styled.section`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 26px 34px;
    justify-content: start;
    align-items: start;
    background-color: #DAE6F8;
    border-radius: 20px;
    margin: 0 auto 50px;
    font-family: Inter;

    @media only screen and (max-width: 800px){
        align-items: center;
        padding: 26px 20px;

        & p{
            text-align: center;
        }
    }

    & h3, p{
        font-family: inherit;
        font-size: 22px;
        font-weight: 500;
    }

    & h3{
        margin: 12px 0 5px 0;
        color: #000000;
    }

    & p{
        text-align: left;
        margin: 10px 0;
        color: #5F5F5F;

        @media only screen and (max-width: 800px){
            text-align: center;
        }
    }

    
`