import React, {Fragment, useState} from "react";
import technologies from './technologies.json';
import {FormControl, InputGroup, Button, Form} from "react-bootstrap";
import {Input, Typeahead} from "react-bootstrap-typeahead";
import {useNavigate} from "react-router-dom";
import {extractWebsiteName} from "../utility/SearchWebsite";
const Autocomplete = () => {
    const [selected, setSelected] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate()

    const techItems = technologies.map((item) => {
        return {
            id: item.id,
            name: item.name
        }
    });

    const handleSelect = (selected) => {
        console.log("Selected:", selected);
        setSelected(selected);
        // Update the URL to the selected suggestion
        window.location.href = '/technology/compact/' + selected[0].id;
    };

    const menuStyle = {
        position: 'fixed',
        backgroundColor: '#fff',
        overflow: 'auto',
        maxHeight: '50vh',
        zIndex: 10000 // Set the zIndex to a high value
    };

    const handleSearch = () => {
        try {
            const {website, error} = extractWebsiteName(inputValue);
            if (error != null) {
                throw error;
            }
            const url = `/websitelookup/${website}`
            navigate(url)
        } catch (error) {
            console.error('Invalid URL:', error);
            setError('Please enter a valid URL.');
        }
    }

    const isWebsiteUrl = (input) => {
        // Regular expression that matches common website URL formats
        const {website, error} = extractWebsiteName(input);
        return error == null;
    };

    return (
        <div>
            <div style={{ width: "100%" }} className="flex-fill">
                <div className="invalid-feedback" style={{ display: 'block', clear: 'both', marginBottom: '10px' }}>
                    <span>{error}</span>
                </div>
            </div>

            <div className="d-flex">
                <div style={{ width: "100%" }} className="flex-fill">
                    <Typeahead
                        className="w-auto mr-2"
                        id="autocomplete"
                        labelKey="name"
                        onChange={handleSelect}
                        options={techItems}
                        placeholder="Website URL, Technologies"
                        selected={selected}
                        inputProps={{ style: { width: "100%" } }} // Set the width of the input to 100%
                        style={{ width: "100%" }} // Set the width of the Typeahead to 100%
                        onInputChange={(input) => {
                            setSelected([]);
                            setInputValue(input);
                        }}
                        onBlur={() => {
                            setSelected([]);
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                handleSearch()
                            }
                        }}
                        onSelect={handleSelect}

                        menuStyle={menuStyle}
                        emptyLabel={`Search website: ${inputValue}`} // Set the empty label to "Search website" with the input value
                        renderMenuItemChildren={(techItems, props) => (
                            <div
                                style={{
                                    color: props.isHighlighted ? '#fff' : '#333',
                                }}
                            >
                                {techItems.name}
                            </div>
                        )}
                    />
                </div>

                <Button variant="primary" onClick={handleSearch} disabled={!isWebsiteUrl(inputValue)}>Search</Button>
            </div>

        </div>

    );
};

export default Autocomplete;
  