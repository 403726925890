import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import 'simplebar-react/dist/simplebar.min.css';
import styles from './Sidebar.module.css'
import SimpleBar from 'simplebar-react';
import { Technology } from '../../views/main-views/technology';

function SideBar(props: any) {
    const location = useLocation()
    const dashboardMenu = [
        {
            id: 1,
            name: "Technology",
            iconClass: "bx-pie-chart",
            slug: "technology",
            subMenu: [
                {
                    id: 1,
                    name: "Search",
                    slug: "search",
                },
                {
                    id: 2,
                    name: "Catalogs",
                    slug: "catalogs",
                },
                {
                    id: 3,
                    name: "Saved Report",
                    slug: "saved-report",
                },
                {
                    id: 4,
                    name: "Competitor Comparison",
                    slug: "competitor-comparison",
                },
            ],
        },
        {
            id: 2,
            name: "Websites",
            iconClass: "bx-world",
            slug: "websites",
            subMenu: [
                {
                    id: 1,
                    name: "Search",
                    slug: "search",
                },
                {
                    id: 2,
                    name: "Keywords Based Lists",
                    slug: "keywords-based-lists",
                },
                {
                    id: 3,
                    name: "Saved Report",
                    slug: "saved-report",
                },
            ],
        },
        {
            id: 3,
            name: "Account",
            iconClass: "bx-user-circle",
            slug: "account",
            subMenu: [
                {
                    id: 1,
                    name: "Profile",
                    slug: "and ",
                },
                {
                    id: 2,
                    name: "Credits",
                    slug: "and ",
                },
                {
                    id: 3,
                    name: "Price Plan",
                    slug: "and ",
                },
                {
                    id: 4,
                    name: "Payments",
                    slug: "and ",
                },
                {
                    id: 5,
                    name: "Orders",
                    slug: "and ",
                },
                {
                    id: 6,
                    name: "Invoices",
                    slug: "and ",
                },
                {
                    id: 7,
                    name: "Email Verifications",
                    slug: "and ",
                },
                {
                    id: 8,
                    name: "Logout",
                    slug: "and ",
                },
            ],
        },
    ]
    const [showChildMenu, setshowChildMenu] = useState(true)
    const [activeMenu, setactiveMenu] = useState(1)
    // const [activeChildMenu, setactiveChildMenu] = useState(1)
    const [activeChildMenu, setactiveChildMenu] = useState(0)
    const [activeSlug, setactiveSlug] = useState("")
    const handleMenuTitleClick = (e: any) => {
        const menuId = parseInt(e.currentTarget.dataset.id)
        if (menuId === activeMenu) {
            setshowChildMenu((showChildMenu) => !showChildMenu)
        } else {
            setactiveMenu(() => menuId)
            // setactiveChildMenu(() => 1)
            setshowChildMenu(true)
        }
    }
    const handleSubMenuClick = (e: any) => {
        const menuId = parseInt(e.currentTarget.dataset.parent)
        const childMenuId = parseInt(e.currentTarget.dataset.id)
        setactiveMenu(() => menuId)
        setactiveChildMenu(() => childMenuId)
    }

    const handleSideBar = (e: any) => {
        // $(".toggle-icon").click(function () {
        //     $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function () {
        //         $(".wrapper").addClass("sidebar-hovered")
        //     }, function () {
        //         $(".wrapper").removeClass("sidebar-hovered")
        //     }))
        // }),
        let { wrapperRef } = props
        wrapperRef = wrapperRef.current
        wrapperRef.classList.contains("toggled") ? wrapperRef.classList.remove("toggled") : wrapperRef.classList.add("toggled")

    }
    
    const showFullSideMenu = (e: any) => {
        // $(".toggle-icon").click(function () {
        //     $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function () {
        //         $(".wrapper").addClass("sidebar-hovered")
        //     }, function () {
        //         $(".wrapper").removeClass("sidebar-hovered")
        //     }))
        // }),
        let { wrapperRef } = props
        wrapperRef = wrapperRef.current
        wrapperRef.classList.contains("toggled") && wrapperRef.classList.remove("toggled")

    }
    useEffect(() => {
        // console.log("props", props)
        // console.log("locaiton", location)
        setactiveSlug(() => location.pathname)
    }, [location])

    return (
        <>
            {/* <div className={styles.sidebarWrapper} data-simplebar="init"> */}
            {/* <SimpleBar style={{ maxHeight: 300 }}>
                    {[...Array(50)].map((x, i) => (
                        <p>{i}</p>
                    ))}
                </SimpleBar> */}

            {/* <SimpleBar id="menu" className={`${styles.metismenu}`} style={{ maxHeight: 300 }}> */}
            <SimpleBar id="menu" className={`${styles.sidebarWrapper} sidebar-wrapper`}>
                <div className={`${styles.sidebarHeader} sidebar-header`}>
                    <div>
                        <img src="/dashboard/images/logo-icon.png" className={`${styles.logoIcon} logo-icon`} alt="logo icon" />
                    </div>
                    <div>
                        <h4 className={`${styles.logoText} logo-text`}>Rocker</h4>
                    </div>
                    <div onClick={handleSideBar} className={`${styles.toggleIcon} ms-auto toggle-icon`}><i className={`bx bx-arrow-to-left`}></i>
                    </div>
                    {/* <div className="toggle-icon ms-auto"><i className='bx bx-arrow-to-left'></i>
                    </div> */}
                </div>
                <ul className={`${styles.metismenu} metismenu`} id="menu">
                    {
                        dashboardMenu.map((item) => (
                            <li key={item.id} className={`${activeMenu === item.id ? "mm-active" : ""}`}>
                                <Link onMouseEnter={showFullSideMenu} to="#" className={`${styles.hasArrow} has-arrow`} data-id={item.id} onClick={handleMenuTitleClick} aria-expanded={showChildMenu && activeMenu === item.id ? "true" : "false"}>
                                    <div className={`${styles.parentIcon} parent-icon`}><i className={`bx ${item.iconClass} icon-class`}></i>
                                    </div>
                                    <div className={`${styles.menuTitle} menu-title`} >{item.name}</div>
                                </Link>
                                {
                                    item.subMenu.length > 0 &&
                                    <ul className={`mm-collapse ${showChildMenu && activeMenu === item.id ? "mm-show" : ""}`}>
                                        {
                                            item.subMenu.map((subItem) => (
                                                <li key={subItem.id} className={`${showChildMenu && activeMenu === item.id && activeChildMenu === subItem.id ? "mm-active" : ""}`} data-id={subItem.id} data-parent={item.id} onClick={handleSubMenuClick}>
                                                    <Link to={`${item.slug}/${subItem.slug}`} aria-expanded={showChildMenu ? "true" : "false"}>
                                                        <i className="bx bx-right-arrow-alt"></i>{subItem.name}
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                }
                                {/* <ul className={`mm-collapse ${showChildMenu ? "mm-show" : ""}`}> */}
                                {/* <li className={`${showChildMenu ? "mm-active" : ""}`}>
                                        <a href="index.html" aria-expanded={showChildMenu ? "true" : "false"}>
                                            <i className="bx bx-right-arrow-alt"></i>Search
                                        </a>
                                    </li>
                                    <li> <a href="index2.html"><i className="bx bx-right-arrow-alt"></i>Catalogs</a>
                                    </li>
                                    <li> <a href="index3.html"><i className="bx bx-right-arrow-alt"></i>Saved Report</a>
                                    </li>
                                    <li> <a href="index3.html"><i className="bx bx-right-arrow-alt"></i>Competitor Comparison</a>
                                    </li> */}
                                {/* </ul> */}
                            </li>
                        ))
                    }
                    {/* <li className={`${showChildMenu ? "mm-active" : ""}`}>
                        <Link to="#" className={`${styles.hasArrow}`} aria-expanded={showChildMenu ? "true" : "false"}>
                            <div className={`${styles.parentIcon}`}><i className='bx bx-home-circle'></i>
                            </div>
                            <div className={`${styles.menuTitle}`} onClick={handleMenuTitleClick}  >Technology</div>
                        </Link>
                        <ul className={`mm-collapse ${showChildMenu ? "mm-show" : ""}`}>
                            <li className={`${showChildMenu ? "mm-active" : ""}`}>
                                <a href="index.html" aria-expanded={showChildMenu ? "true" : "false"}>
                                    <i className="bx bx-right-arrow-alt"></i>Search
                                </a>
                            </li>
                            <li> <a href="index2.html"><i className="bx bx-right-arrow-alt"></i>Catalogs</a>
                            </li>
                            <li> <a href="index3.html"><i className="bx bx-right-arrow-alt"></i>Saved Report</a>
                            </li>
                            <li> <a href="index3.html"><i className="bx bx-right-arrow-alt"></i>Competitor Comparison</a>
                            </li>
                        </ul>
                    </li> */}
                    {/* <li>
                        <a href="javascript:;" className={`${styles.hasArrow}`}>
                            <div className={`${styles.parentIcon}`}><i className="bx bx-category"></i>
                            </div>
                            <div className={`${styles.menuTitle}`}>Websites</div>
                        </a>
                        <ul>
                            <li> <a href="app-emailbox.html"><i className="bx bx-right-arrow-alt"></i>Search</a>
                            </li>
                            <li> <a href="app-chat-box.html"><i className="bx bx-right-arrow-alt"></i>Keywords Based Lists</a>
                            </li>
                            <li> <a href="app-file-manager.html"><i className="bx bx-right-arrow-alt"></i>Saved Report</a>
                            </li>
                        </ul>
                    </li> */}
                    {/* <li className={`${styles.menuLabel}`}>UI Elements</li>
                    <li>
                        <a href="widgets.html">
                            <div className={`${styles.parentIcon}`}><i className='bx bx-cookie'></i>
                            </div>
                            <div className={`${styles.menuTitle}`}>Widgets</div>
                        </a>
                    </li> */}
                    {/* <li>
                        <a href="javascript:;" className={`${styles.hasArrow}`}>
                            <div className={`${styles.parentIcon}`}><i className='bx bx-cart'></i>
                            </div>
                            <div className={`${styles.menuTitle}`}>Account</div>
                        </a>
                        <ul> */}
                    {/* <li> <a href="ecommerce-products.html"><i className="bx bx-right-arrow-alt"></i>Products</a>
                            </li>
                            <li> <a href="ecommerce-products-details.html"><i className="bx bx-right-arrow-alt"></i>Product
                                Details</a>
                            </li>
                            <li> <a href="ecommerce-add-new-products.html"><i className="bx bx-right-arrow-alt"></i>Add New
                                Products</a>
                            </li>
                            <li> <a href="ecommerce-orders.html"><i className="bx bx-right-arrow-alt"></i>Orders</a>
                            </li> */}
                    {/* </ul>
                    </li> */}
                </ul>
            </SimpleBar>
            {/* </div> */}
        </>
    )
}

export default SideBar