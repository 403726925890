import { Footer } from '../../components/footer';
import { WebsiteLookup } from '../../components/websiteLookup';

const WebsiteLookupView = () => {
    return (
        <div id='wrapper'>
            <WebsiteLookup />
            <Footer />
        </div>  
    )
}

export default WebsiteLookupView;