import React from 'react';
import {TitleDiv} from './styled-title'
import PlaceholderLoading from 'react-placeholder-loading'

type titleProps = {
    name: string;
    description: string;
    icon: string;
    website: string;
}

const Title = ({name, description, icon, website}: titleProps) => {
    
    const Icon = () => (<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_225_291)">
        <path d="M16.7727 11.25V18.1667C16.7727 19 16.1181 19.5834 15.3818 19.5834H1.79997C0.981785 19.5834 0.409058 18.9167 0.409058 18.1667V4.33335C0.409058 3.50002 1.0636 2.91669 1.79997 2.91669H8.59088" stroke="#017DFC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.3181 0.416687L17.5908 3.75002" stroke="#017DFC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.3181 7.08333L17.5908 3.75" stroke="#017DFC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.77271 13.75L7.93634 10.8333C8.01816 8.75 8.99998 6.66667 10.6363 5.33333C11.8636 4.33333 13.5 3.75 15.1363 3.75H17.5909" stroke="#017DFC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_225_291">
        <rect width="18" height="20" fill="white"/>
        </clipPath>
        </defs>
    </svg>)

    return (
        <TitleDiv>
            <div className='title'>
                { !name || !website ?
                    <>
                        <PlaceholderLoading shape="circle" width={50} height={50} />
                        <PlaceholderLoading shape="rect" width={(window.innerWidth < 600) ? 200 : 372} height={57} />
                    </>
                    :
                    <>
                        <div className='icon'><img src={`${process.env.PUBLIC_URL}/images/icons/${icon}`} /></div>
                        <div className='name'>{name}</div>
                        <a href={website} className='website' rel="noopener noreferrer" target='_blank'>Website<Icon /></a>
                    </>
                }
            </div>
            {description || description === '' ? <p className='description'>{description}</p> : <PlaceholderLoading shape="rect" width={(window.innerWidth < 600) ? 350 : 821} height={40} />}
        </TitleDiv>
    )
}

export default Title