import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

function Footer(props:any) {
  const { technology } = props;
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the current year every time the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <>
      <footer
        className={
          technology === 0 ? "footer-style footer-light" : "footer-light"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="widget ">
                <h5>Subscribe to receive occasional product update</h5>

                <div className="d-flex ">
                  <input
                    type="email"
                    className="form-control mr-2"
                    placeholder="info@example.com"
                    name="emailName"
                    id=""
                  />
                  <Link
                    to=""
                    className="btn-custom d-flex"
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#f0ebf9",
                      color: "#4608ad",
                      padding: "5px 15px",
                    }}
                  >
                    <i className="icon_mail p-1"></i>Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            {/* <div className="col-lg-4">
                            <div className="widget">
                                <Link to="" href="index.html">
                                    <img alt="" className="logo" src="images/logo-1.png" />
                                </Link>
                                <div className="spacer-20"></div>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.</p>
                            </div>
                        </div> */}

            <div className="col-lg-4">
              <div className="widget">
                <h5>Products</h5>
                <ul>
                  <li>
                    <Link to="/websitelookup">
                      Web Technology Lookup
                    </Link>
                  </li>
                  <li>
                    <Link to="/technologies">
                      Web Technology Usage Lists
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/detailed-website-profiles">
                      Detailed Website Profiles
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/competitor-comparison">
                      Competitor Comparison
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/technology-market-share">
                      Technology Market Share
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/keyword-based-lists">Keyword Based Lists</Link>
                  </li>
                </ul>
              </div>
            </div>

            {/*<div className="col-lg-2">*/}
            {/*  <div className="widget">*/}
            {/*    <h5>Use Cases</h5>*/}
            {/*    <ul>*/}
            {/*      <li>*/}
            {/*        <Link to="/lead-generation">Lead generation</Link>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <Link to="/market-research">Market research</Link>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <Link to="/competitor-analysis">Competitor analysis</Link>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <Link to="/data-enrichment">Data enrichment</Link>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <Link to="/security-reason">Security reason</Link>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="col-lg-4">
              <div className="widget">
                <h5>Pricing</h5>
                <ul>
                  <li>
                    <Link to="">Pricing</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="widget">
                <h5>Resources</h5>
                <ul>
                  <li>
                    <Link to="/articles">Articles</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/*<div className="col-lg-2">*/}
            {/*  <div className="widget">*/}
            {/*    <h5>Company</h5>*/}
            {/*    <ul>*/}
            {/*      <li>*/}
            {/*        <Link to="/about-us">About-us</Link>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <Link to="/contact-us">Contact us</Link>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/* <div className="col-lg-4">
                            <div className="widget">
                                <h5>Newsletter</h5>

                                <p>Signup for our newsletter to get the latest news, updates and special offers in your inbox.</p>
                                <form action="blank.php" className="row" id="form_subscribe" method="post" name="form_subscribe">
                                    <div className="col text-center">
                                        <input className="form-control" id="name_1" name="name_1" placeholder="enter your email" type="text" /> <Link to="" id="btn-submit"><i className="arrow_right"></i></Link>
                                        <div className="clearfix"></div>
                                    </div>
                                </form>
                                <div className="spacer-10"></div>
                                <small>Your email is safe with us. We don't spam.</small>
                            </div>
                        </div> */}
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6 text-sm-left mb-sm-30">
              <div className="mt10">&copy; Copyright {currentYear} - Webrofiler</div>
            </div>

            <div className="col-md-6 text-md-right text-sm-left">
              <div className="social-icons">
                <Link to="">
                  <i className="fa fa-facebook fa-lg"></i>
                </Link>
                <Link to="">
                  <i className="fa fa-twitter fa-lg"></i>
                </Link>
                <Link to="">
                  <i className="fa fa-linkedin fa-lg"></i>
                </Link>
                <Link to="">
                  <i className="fa fa-google-plus fa-lg"></i>
                </Link>
                <Link to="">
                  <i className="fa fa-rss fa-lg"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer
