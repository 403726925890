import { useState, useEffect } from 'react';
import { Main } from './styled-productfeature';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Button} from "react-bootstrap";

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Competitor Comparison', path: '/feature/competitor-comparison'},
]

const CompetitorComparisonFeaturePage = () => {

  useEffect(() => {

},[])

  return ( 
    <Main>
        <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <div className='content-container'>
                <h1 className="text-center">Competitor Comparison</h1>
                <div className="descDiv">
                  <p className='pageDescription'>Competitor Comparison is a feature that enables users to compare and analyze two technologies side by side to gain insights into which one is currently leading or performing better. By examining the data and metrics, users can make informed decisions about which technology is prevailing in terms of popularity, adoption, and overall market dominance.

                  </p>
                </div>
                <div>
                  <img className="screenshot" src="/images/features/competitor-comparison.png" alt="screenshot1"></img>
                </div>
                <div className="signupDiv">
                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`}>
                        <Button variant='primary' size='lg'>Sign Up for Free</Button>
                    </a>
                </div>
            </div>
        </div>
    </Main>
  )
}

export default CompetitorComparisonFeaturePage