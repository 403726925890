import { useEffect, useState } from 'react';
import {EmailDiv} from './styled-emailRequest';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const EmailRequest = ({email, error, emailHandler}: any) => {

    const [isLoading, setLoading] = useState<boolean>(false);
    const completed = email ? true : false;
    const [input, setInput] = useState<string>('')

    const handleClick = () => {
        setLoading(true);
        emailHandler(input);
    }

    useEffect(()=>{if(email || error.msg) setLoading(false);}, [email, error])

    return (
        <EmailDiv>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control 
                        type="email" 
                        placeholder={email ? email : "Enter email" }
                        value={input}
                        onChange={(e)=>setInput(e.target.value)}
                    />
                </Form.Group>
                <Button
                    variant="primary"
                    disabled={isLoading || completed}
                    onClick={(!isLoading && !completed) ? handleClick : undefined}
                    >
                    {isLoading ? <Spinner animation="border" variant="light" /> : completed ? 'Done' : 'Receive Email Notification'}
                </Button>
            </Form> 
            {error ? <div className='errorMsg'>{error.msg}</div> : ''}
        </EmailDiv>
    )
}

export default EmailRequest