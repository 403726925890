import Alert from 'react-bootstrap/Alert';

export const icons = {
  danger: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_513_2279)">
  <rect width="16" height="16" fill="white" fillOpacity="0.01"/>
  <g clipPath="url(#clip1_513_2279)">
  <path d="M8.98164 1.56522C8.88265 1.39278 8.73992 1.24952 8.56785 1.14989C8.39578 1.05026 8.20047 0.997803 8.00164 0.997803C7.80281 0.997803 7.6075 1.05026 7.43543 1.14989C7.26337 1.24952 7.12063 1.39278 7.02164 1.56522L0.164641 13.2322C-0.292359 14.0102 0.255641 14.9992 1.14464 14.9992H14.8576C15.7466 14.9992 16.2956 14.0092 15.8376 13.2322L8.98164 1.56522ZM7.99964 4.99922C8.53464 4.99922 8.95364 5.46122 8.89964 5.99422L8.54964 9.50122C8.53788 9.63899 8.47484 9.76733 8.373 9.86086C8.27115 9.95438 8.13791 10.0063 7.99964 10.0063C7.86137 10.0063 7.72813 9.95438 7.62629 9.86086C7.52444 9.76733 7.4614 9.63899 7.44964 9.50122L7.09964 5.99422C7.08707 5.86845 7.10099 5.74145 7.14049 5.62139C7.17999 5.50132 7.2442 5.39087 7.32898 5.29713C7.41377 5.20339 7.51725 5.12846 7.63276 5.07715C7.74827 5.02584 7.87325 4.99929 7.99964 4.99922ZM8.00164 10.9992C8.26686 10.9992 8.52121 11.1046 8.70875 11.2921C8.89628 11.4796 9.00164 11.734 9.00164 11.9992C9.00164 12.2644 8.89628 12.5188 8.70875 12.7063C8.52121 12.8939 8.26686 12.9992 8.00164 12.9992C7.73642 12.9992 7.48207 12.8939 7.29453 12.7063C7.107 12.5188 7.00164 12.2644 7.00164 11.9992C7.00164 11.734 7.107 11.4796 7.29453 11.2921C7.48207 11.1046 7.73642 10.9992 8.00164 10.9992Z" fill="#842029"/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_513_2279">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  <clipPath id="clip1_513_2279">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  </defs>
  </svg>,
  primary: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_513_2289)">
  <rect width="16" height="16" fill="white" fillOpacity="0.01"/>
  <g clipPath="url(#clip1_513_2289)">
  <path d="M15.9998 7.99915C15.9998 10.1209 15.1569 12.1557 13.6566 13.656C12.1563 15.1563 10.1215 15.9991 7.99976 15.9991C5.87802 15.9991 3.84319 15.1563 2.3429 13.656C0.842611 12.1557 -0.000244141 10.1209 -0.000244141 7.99915C-0.000244141 5.87741 0.842611 3.84258 2.3429 2.34229C3.84319 0.842 5.87802 -0.000854492 7.99976 -0.000854492C10.1215 -0.000854492 12.1563 0.842 13.6566 2.34229C15.1569 3.84258 15.9998 5.87741 15.9998 7.99915ZM12.0298 4.96915C11.9583 4.89796 11.8733 4.84192 11.7797 4.80436C11.6861 4.7668 11.5859 4.7485 11.4851 4.75056C11.3843 4.75261 11.2849 4.77498 11.1929 4.81631C11.1009 4.85765 11.0182 4.91711 10.9498 4.99115L7.47676 9.41615L5.38376 7.32215C5.24158 7.18967 5.05353 7.11754 4.85923 7.12097C4.66493 7.1244 4.47955 7.20311 4.34213 7.34052C4.20472 7.47794 4.12601 7.66332 4.12258 7.85762C4.11915 8.05192 4.19128 8.23997 4.32376 8.38215L6.96976 11.0291C7.04104 11.1003 7.12592 11.1564 7.21934 11.194C7.31276 11.2316 7.4128 11.2501 7.5135 11.2482C7.6142 11.2463 7.71349 11.2242 7.80545 11.1831C7.89741 11.1421 7.98016 11.0829 8.04876 11.0091L12.0408 6.01915C12.1768 5.87764 12.252 5.68842 12.2502 5.4921C12.2483 5.29579 12.1695 5.10803 12.0308 4.96915H12.0298Z" fill="#0F5132"/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_513_2289">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  <clipPath id="clip1_513_2289">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  </defs>
  </svg>
}

type AlertProps = {
  message: string;
  variant: 'danger' | 'primary';
}

const AlertCreator = ({message, variant}: AlertProps) => {
  return (
    <div style={{fontFamily: 'Nunito', fontSize: '22px'}}>
      <Alert key={variant} variant={variant}>
        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
          <span className='mr-2'>{icons[variant]}</span>
          <span>{message}</span>
        </div>
      </Alert>
    </div>
  )
}

export default AlertCreator