import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import { Footer } from '../../../components/footer'
import { Header } from '../../../components/header'
import { Link, Outlet } from 'react-router-dom'

function Main() {
    return (
        <>
            <Header />
            <div id="wrapper">
                <Outlet/>
            </div>
        </>
    )
}

export default Main