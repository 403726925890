import { Link } from "react-router-dom";
import PlaceholderLoading from 'react-placeholder-loading';

export const styles = {
    mainDiv: {marginBottom: '40px'},
    item: {color: '#017DFC', textDecoration: 'none', fontSize: '18px', fontFamily: 'Inter'}, 
    span: {color: '#000000'}
}

export default function RouteCreator({routes}) { 
    if(Array.isArray(routes)){
        if(routes.length > 1){
            return (
                <div style={styles.mainDiv}>
                    {routes.map((route, index) => 
                        (route.name !== undefined) 
                        ? 
                        <Link to={route.path ? route.path : ''} style={styles.item} key={`route-${index}`}>{route.name}{index !== routes.length-1 ? <span style={styles.span}> / </span> : ''}</Link>
                        : 
                        <PlaceholderLoading shape="rect" width={72} height={25} key={`route-${index}`} />
                    )}
                </div>
            )
        } 
        return <div className="address-header-div">Home</div>
    }
    return <div></div>;
}