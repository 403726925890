import styled from "styled-components";

export const Main = styled.main`
    min-height: 800px;
    position: relative;
    background-color: #ffffff;

    & a{
        @media (max-width: 500px){
            font-size: 15px !important;
        }
    }

    & h1, p{
        font-family: Nunito
    }

    & h1{
        margin-bottom: 10px !important;
        font-size: 52px;
    }
    & p.pageDescription{
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 110px;
    }

    & div.accordion{
        box-sizing: border-box;
        width: 100%;
        max-width: 877px;
        margin: 0 auto 20px;

        & div.accordion-item{
            margin-top: 20px;

            & h2.accordion-header{
                width: 100%;
                height: 100%;
                margin-bottom: -6px;
                
                & button{
                    background-color: #e9ecef;
                    font-weight: 500;
                    font-size: 24px;
                    height: 100%;
                    color: black;

                    &:not(.collapsed):after{
                        transform: rotate(0deg);
                    }

                    &:after{
                        transform: rotate(90deg);
                    }
                }
            }
        }

        & div.inputDiv{
            width: 100%;
            position: relative;

            & input{
                margin: 24px auto;
            }

            & svg{
                position: absolute;
                right: 0;
                top: 0;
                margin: 10px 15px 0 0;
            }
        }
    }

    & p.uploadFileTitle{
        font-size: 22px;
    }
`