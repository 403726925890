import { Footer } from '../../components/footer';
import { WebsiteLookupResult } from '../../components/websiteLookupResult';

const WebsiteLookupResultView = () => {
    return (
        <div id='wrapper'>
            <WebsiteLookupResult />
            <Footer />
        </div>  
    )
}

export default WebsiteLookupResultView;