import styled from "styled-components";

export const Main = styled.main`
    min-height: 800px;
    position: relative;
    background-color: #ffffff;

    & a{
        @media (max-width: 500px){
            font-size: 15px !important;
        }
    }

    & h1, p{
        font-family: Nunito
    }

    & h1{
        margin-bottom: 10px !important;
        font-size: 40px;
        font-weight: bold;
    }
    & p.pageDescription{
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 110px;
    }

    & div.accordion{
        box-sizing: border-box;
        width: 100%;
        max-width: 877px;

        & div.accordion-item{
            margin-top: 20px;

            & h2.accordion-header{
                width: 100%;
                height: 100%;
                margin-bottom: -6px;
                
                & button{
                    background-color: #e9ecef;
                    font-weight: 500;
                    font-size: 1.25rem;;
                    height: 100%;
                    color: black;

                    &:not(.collapsed):after{
                        transform: rotate(0deg);
                    }

                    &:after{
                        transform: rotate(90deg);
                    }
                }
            }
        }

        & div.inputDiv{
            width: 100%;
            position: relative;

            & input{
                margin: 24px auto;
            }

            & svg{
                position: absolute;
                right: 0;
                top: 0;
                margin: 10px 15px 0 0;
            }
        }
    }

    & p.uploadFileTitle{
        font-size: 22px;
    }

    .v-application {
        display: flex;
        position: relative;
    }

    .v-application {
        font-family: Averta,Arial,sans-serif;
        line-height: 1.5;
    }
    
    .v-application .secondary {
        background-color: #f0f0f0 !important;
        border-color: #f0f0f0 !important;
    }
    
    .v-navigation-drawer {
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-direction: column;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        pointer-events: auto;
        top: 0;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-property: transform,visibility,width;
    }

    .v-navigation-drawer__content {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }    

    .v-application .primary--text {
        color: #000000 !important;
    }

    .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: #35404e;
    }

    .v-list-item--active:before {
        background-color: #0D6EFD;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: .3s cubic-bezier(.25,.8,.5,1);
    }

    .v-list-item:hover {
        background-color: #e0e0e0;
    }

    .v-sheet.v-list:not(.v-sheet--outlined) {
        box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
    }

    .v-sheet.v-list {
        border-radius: 0;
    }
    
    .v-application .px-0 {
        padding-right: 0!important;
        padding-left: 0!important;
    }    

    .v-list {
        display: block;
        padding: 8px 0;
        position: static;
        transition: box-shadow .28s cubic-bezier(.4,0,.2,1);
    }    

    .v-list--nav .v-list-item--dense:not(:last-child):not(:only-child), .v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child), .v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child) {
        margin-bottom: 4px;
    }
    
    .v-list--dense .v-list-item, .v-list-item--dense {
        min-height: 40px;
    }

    .v-list--nav .v-list-item, .v-list--nav .v-list-item:before {
        border-radius: 4px;
    }

    .v-list--nav .v-list-item {
        padding: 0 8px;
    }

    .v-application .mx-2 {
        margin-right: 8px
    }

    .v-list-item {
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        letter-spacing: normal;
        min-height: 48px;
        outline: 0;
        padding: 0 16px;
        position: relative;
        text-decoration: none;
    }
    
    .v-list--dense .v-list-item .v-list-item__icon, .v-list-item--dense .v-list-item__icon {
        height: 24px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    
    .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: 32px;
    }

    .v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
        display: inline-flex;
        min-width: 24px;
    }

    .v-list-item__icon {
        align-self: flex-start;
        margin: 16px 0;
    }
    
    .v-list--dense .v-list-item .v-list-item__content, .v-list-item--dense .v-list-item__content {
        padding: 8px 0;
    }

    .v-list-item__content {
        align-items: center;
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        flex: 1 1;
        overflow: hidden;
        padding: 12px 0;
    }
    
    .v-list--dense .v-list-item .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list-item--dense .v-list-item__title {
        font-size: .9rem;
        font-weight: 500;
        line-height: 1rem;
    }
    
    .v-list-item__title {
        align-self: center;
        font-size: 1rem;
    }

    .v-list-item__subtitle, .v-list-item__title {
        flex: 1 1 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .v-list-item--active:before, .v-list-item--active:hover:before, .v-list-item:focus:before {
        opacity: .24;
    }    

    div.accordion div.accordion-item h2.accordion-header button {
        font-size: 1.1rem;
    }    
    
`