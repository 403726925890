import styled from "styled-components";

export const TableDiv = styled.section`
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
    margin: 0 auto 50px;

    & h2{
        font-family: Nunito;
        font-weight: 500;
        font-size: 30px;
        margin: 30px 0 15px 0;
        color: #000000;
        letter-spacing: 1px;
        width: 100%;

        @media only screen and (max-width: 800px){
            text-align: center;
            font-size: 25px;
        }
    }

    & div.tableDiv{
        padding: 0 15px;
        overflow: auto;
        margin: 30px 0 0 0;
    }

    & table{
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        border-spacing: 0;
        overflow: auto;

        & .minWidth{
            min-width: 200px;
        }

        & th{
            padding: 0 5px 10px 5px;
            font-size: 14px;
            color: gray;

            & div{
                display: flex;
                align-items: center;
                cursor: pointer;

                & svg{
                    height: 15px;
                    width: 15px;
                    color: blue;
                    margin-left: 5px;
                }
            }
        }

        & td{
            padding: 10px 5px;
            border-bottom: 1px solid #80808033;
        }

        & tr.tableItem{
            transition: all .2s;
            &:hover{
                background-color: #8080802b
            }
        }

        & a.blueColor{
            color: #09a5ff;
            font-weight: 400;
            display: flex;
        }

        & div.progressContainer {
            width: 100%;
            height: 15px;
            background-color: #f0ebf9;
            border-radius: 5px;
            max-width: 100%;

            &:hover{
                height: 17px;
            }
        }

        & img{
            border-radius: 50%;
            width: 20px;
            height: 20px;
            box-shadow: 0px 1px 3px 0px grey;
            margin: auto 15px auto 2px;
        }

        
    }
`

export const Progress = styled.div`
    background-color: #402aa9;
    border-radius: 5px;
    height: 15px;
    color: white;
    font-size: 11px;
    width: ${props => props.width}%;
    max-width: 100%;

        &:hover{
            height: 17px;
        }

        & span{
            padding-left: 5px;
        }
`