import React from 'react';
import { TableDiv, Progress } from './styled-table';
import PlaceholderLoading from 'react-placeholder-loading';
import findPercentageMax from '../utils/findPercentageMax';

type tableProps = {
    websites: {website: string; traffic: number}[];
    title: string;
}

const Table = ({ websites, title }: tableProps) => {
    const percent = findPercentageMax(websites);

    return (
        <TableDiv>
            {title ? <h2>Top Websites using {title}</h2> : <PlaceholderLoading shape="rect" width={523} height={41} />}
            <div className='tableDiv'>
                <table>
                    <thead>
                        <tr key={'table-header'}>
                            <th>#</th>
                            <th>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        {websites.length ? websites.map(({ website, traffic }, index) => (
                            <tr className='tableItem' key={index}>
                                <td style={{width: '4%'}}>{index+1}</td>
                                <td style={{width: '48%'}} className='minWidth'>
                                    <a href={`http://${website}`} className='blueColor' rel="noopener noreferrer" target='_blank'>{website}</a>
                                </td>
                            </tr>
                        )) : (
                            [...Array(10)].map((item, index) => (
                                <tr className='tableItem' key={index}>
                                    <td style={{width: '4%'}}>{index+1}</td>
                                    <td style={{width: '48%'}} className='minWidth'>
                                        <PlaceholderLoading shape="rect" width={300} height={20} />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </TableDiv> 
    )
}

export default Table;