import { useState } from 'react';
import { Main } from './styled-websiteLookup';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import Upgrade from '../websiteLookupResult/upgrade/Upgrade';
import Accordion from 'react-bootstrap/Accordion';
import {Form} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import {extractWebsiteName} from "../utility/SearchWebsite";

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Technology lookup', path: '/websitelookup'},
]

const WebsiteLookup = () => {
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState('')

  function searchWebsite(value: string) {
    try {
      setError('')
      const {website, error} = extractWebsiteName(value);
      if (error != null) {
        throw error;
      }
      const url = `/websitelookup/${website}`
      navigate(url)
    } catch (error) {
      console.error('Invalid URL:', error);
      setError('Please enter a valid URL.');
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default Enter key behavior
      searchWebsite(inputValue);
    }
  };
  const navigate = useNavigate()

  return ( 
    <Main>
      <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
        <RouteCreator routes={routesInit} />
        <h1>Website Technology Lookup</h1>
        <p className='pageDescription'>Find out what websites are built with</p>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Look up a website</Accordion.Header>
            <Accordion.Body>
              <Form>
                <Form.Group controlId="websiteInput">
                  <div className='inputDiv'>
                  <Form.Control
                      value={inputValue}
                      onChange={({target: {value}})=>setInputValue(value)}
                      onKeyPress={handleKeyPress}
                      type='text'
                      placeholder={'www.example.com'}
                      isInvalid={error !== ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error}
                  </Form.Control.Feedback>
                    { error === '' && <Search /> }
                  </div>
                </Form.Group>
              </Form>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Look up a list of websites</Accordion.Header>
            <Accordion.Body>
              <p className='uploadFileTitle'>Upload a CSV or TXT file with up to 100,000 URLs, each on a separate line.</p>
              <Form>
                <Form.Group controlId="websiteInput">
                  <div className='inputDiv'>

                  <Form.Control
                      value={inputValue}
                      onChange={({target: {value}})=>setInputValue(value)}
                      onKeyPress={handleKeyPress}
                      placeholder='Select a file to upload'
                      disabled={true}
                  />
                  <Search />
                    </div>
                </Form.Group>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Upgrade />
      </div>
    </Main>
  )
}

export default WebsiteLookup