import styled from "styled-components";

export const Main = styled.main`
    min-height: 800px;
    position: relative;
    background-color: #ffffff;

    & div.loading{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 100px;
        display: flex;
        flex-direction: column;

        & span.title{
            font-family: Nunito;
            font-weight: 700;
            font-size: 22px;
            color: #000000;
            text-aling: center;
        }
    }

    & a{
        @media (max-width: 500px){
            font-size: 15px !important;
        }
    }

    & div.websiteDetails{
        & h1{
            color: #000000;
            margin-bottom: 30px;
            font-weight: 700;
        }
    }
`