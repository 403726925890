import { useState } from 'react';
import { Main } from './styled-pricing';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import Upgrade from '../websiteLookupResult/upgrade/Upgrade';
import Accordion from 'react-bootstrap/Accordion';
import {Button, Form} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Pricing', path: '/pricing'},
]

const Pricing = () => {
  const Input = ({ searchWebsite, placeholder, type='text', disabled=false }: any) => {
    const [inputValue, setInputValue] = useState('')

    function inputHandler({key, target: {value}}: any){if(key === 'Enter') searchWebsite(value)}

    return ( 
      <div className='inputDiv'>
        <Form.Control 
          value={inputValue} 
          onChange={({target: {value}})=>setInputValue(value)} 
          onKeyUp={(e)=>inputHandler(e)}
          type={type} 
          placeholder={placeholder} 
          disabled={disabled}  
        />
        <Search />
      </div>
    )
  }

  const navigate = useNavigate()

  function searchWebsite(value: string){if(value.length) navigate(value)}

  const toggleBillingPeriode = () => {
    
  }


  return ( 
    <Main>
        <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <h1 className="text-center">Pricing Plan</h1>
            <p className='pageDescription text-center'>Awsome Page Teaser Here</p>

            <div className="container">
				<div className="row">
                    <div className="col text-center">
                        <div className="switch-set">
                            <div>Monthly</div>								
                            <div><input id="sw-1" className="switch" type="checkbox" onClick={toggleBillingPeriode} /></div>					
                            <div>Yearly</div>
                            <div className="spacer-20"></div>
                        </div>           
                    </div>
                </div>

                <div className="item pricing">
                    <div className="container">
                        <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="pricing-s1 mb30">
                                    <div className="top">
                                        <h2>Free</h2>
                                        <p className="plan-tagline">Limited access</p>
                                    </div>
                                    <div className="mid text-light bg-grey">
                                        <p className="price text-black">
                                            <span className="currency">$</span>
                                            <span className="m opt-1">0.00</span>
                                            <span className="y opt-2">0.00</span>
                                            <span className="month">p/mo</span>
                                        </p>               
                                    </div>
                                    
                                    <div className="bottom">

                                        <ul>
                                            <li><i className="fa fa-check"></i>10 Websites Lookup / Day</li>
                                            <li><i className="fa fa-check"></i>10 Technologies Views / Day</li>
                                            <li><i className="fa fa-ban" style={{ color: 'gray' }}></i>Metadata Export</li>
                                            <li><i className="fa fa-ban" style={{ color: 'gray' }}></i>Advanced Filtering</li>
                                        </ul>
                                    </div>
                                    
                                    <div className="action">
                                        <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`} >
                                            <Button variant="primary"  >Sign Up Now</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="pricing-s1 mb30">
                                    <div className="top">
                                        <h2>Premium</h2>
                                        <p className="plan-tagline">Fully access</p>
                                    </div>
                                        <div className="mid text-light bg-color-2">
                                        <p className="price">
                                            <span className="currency">$</span>
                                            <span className="m opt-1">9.59</span>
                                            <span className="y opt-2">7.46</span>
                                            <span className="month">p/mo</span>
                                        </p>     
                                    </div>
                                    <div className="bottom">
                                        <ul>
                                            <ul>
                                                <li><i className="fa fa-check"></i>Unlimited Websites Lookup / Day</li>
                                                <li><i className="fa fa-check"></i>Unlimited Technologies Views / Day</li>
                                                <li><i className="fa fa-check"></i>Metadata Export</li>
                                                <li><i className="fa fa-check"></i>Advanced Filtering</li>
                                            </ul>
                                        </ul>
                                    </div>
                                    
                                    <div className="action">
                                        <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`} >
                                            <Button variant="primary"  >Sign Up Now</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-lg-4 col-md-6 col-sm-12">*/}
                            {/*    <div className="pricing-s1 mb30">*/}
                            {/*        <div className="top">*/}
                            {/*            <h2>Business</h2>*/}
                            {/*            <p className="plan-tagline">Best for organization</p>*/}
                            {/*        </div>*/}
                            {/*        <div className="mid text-light bg-color-3">*/}
                            {/*            <p className="price">*/}
                            {/*                <span className="currency">$</span>*/}
                            {/*                <span className="m opt-1">24.99</span>*/}
                            {/*                <span className="y opt-2">16.49</span>*/}
                            {/*                <span className="month">p/mo</span>*/}
                            {/*            </p>     */}
                            {/*        </div>*/}
                            {/*        <div className="bottom">*/}
                            {/*            <ul>*/}
                            {/*                <li><i className="fa fa-check"></i>6x Processing Power</li>*/}
                            {/*                <li><i className="fa fa-check"></i>Unlimited Website</li>*/}
                            {/*                <li><i className="fa fa-check"></i>100GB Disk Space</li>*/}
                            {/*                <li><i className="fa fa-check"></i>Easy Website Builder</li>*/}
                            {/*                <li><i className="fa fa-check"></i>99.9% Uptime Guarantee</li>*/}
                            {/*                <li><i className="fa fa-check"></i>24/7 Customer Support</li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*        */}
                            {/*        <div className="action">*/}
                            {/*            <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`} className="btn-custom">Sign Up Now</a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </Main>
  )
}

export default Pricing    