import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  ${css`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0.5rem 1rem;
    color: #000000;
  `}
`;

export default StyledLink;