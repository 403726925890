import React from 'react'
import {TechStackDiv} from './styled-tabs'

const TechStack = ({categories}:any) => {
  return (
    <TechStackDiv>
        {categories?.length
        ?
        categories.map(({category: {name, id}, technologies}: any, index: number) =>
            <div className='catDiv' key={`${name}-${index}`}>
                <div className='name'>
                  {name}
                  <div>
                    <a className="small" href="/technology" style={{ cursor: 'pointer !important' }}>
                      View All Statistics
                    </a>
                  </div>
                </div>
                <div className='tech'>
                    {technologies.map(({id, name, icon, version,description}: any, index: number) => (
                        // <div className='techDetails' key={`${name}-${index}`}>
                        //   <div className="col-4" style={{ "height": "50px", "width": "50px", "display": "flex", "justifyContent": "center", "alignItems": "center", "background": "#E9ECEF", "boxShadow": "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "50%", maxWidth: "50px", maxHeight: "50px" }}>
                        //     <a href={`/technology/compact/${id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        //       <img src={`${process.env.PUBLIC_URL}/images/icons/${icon}`} style={{ 'height': '32px', 'width': '32px', 'margin': '0px' }} />
                        //     </a>
                        //   </div>
                        //     <a href={`/technology/compact/${id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        //     <div>{name}</div>
                        //     {version ? <span>{version}</span> : ''}
                        //     </a>
                        //   <div className="clearfix">
                        //     <div className="col-md-12">
                        //       <span>{description}</span>
                        //     </div>
                        //   </div>
                        // </div>
                        <div className="mt-2 mb-2">
                          <div className="d-flex align-items-center mt-4">
                            <div className="d-flex justify-content-center align-items-center mb-2">
                            <a href={`/technology/compact/${id}`} style={{ color: 'inherit', textDecoration: 'none' }} className="mr-2">
                              <img src={`${process.env.PUBLIC_URL}/images/icons/${icon}`} style={{ 'height': '32px', 'width': '32px', 'margin': '0px' }} />
                            </a>
                            <h5>{name}</h5>
                            </div>
                          </div>
                          <div>
                            <a href="/link" className="">
                              Download List of All Websites using {name}
                            </a>
                          </div>
                          <div>
                            {description}
                          </div>
                        </div>
                    ))}
                </div>
            </div>
        )
        :
        <div className='notFound'>Technology stack is not found for this website</div>
        }
    </TechStackDiv>
  )
}

export default TechStack