import React from 'react'
import { UpgradeDiv } from './styled-upgrade'
import PlaceholderLoading from 'react-placeholder-loading'
import {Button} from "react-bootstrap";

const Upgrade = ({count}: any) => {
  return (
    <UpgradeDiv>
        <h3>Upgrade to Premium</h3>
        <p>
          Create a list of websites using WordPress plugins with company and contact details.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`} >
                <Button variant='primary' size='lg'>Upgrade Now</Button>
            </a>
        </div>
    </UpgradeDiv>
  )
}

export default Upgrade