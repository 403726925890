import React from 'react';
import { MetadataDiv } from './styled-tabs';
import iconProvider from './iconProvider';

const Contact = (contacts: any) => {
  const checkEmptyContacts = Object.values(contacts).findIndex(c => c !== null && c !== undefined && c !== '')

  if(checkEmptyContacts < 0) return (
    <MetadataDiv>
      <div className='notFound'>Contact details is not found for this website</div>
    </MetadataDiv>
  )

  return (
    <MetadataDiv>
        {Object.keys(contacts).map((contact, index)=>(
          <div key={`${contact}-${index}`}>
              <div className="title">{contact.includes('_') ? 'Company' : contact.charAt(0).toUpperCase() + contact.slice(1)}</div>
              {contact === 'company_name'
              ?
              <div className="content"><span>{(typeof contacts[contact]) === 'string' ? contacts[contact] : 'No Name Provided'}</span></div>
              :
              <div className="content contactItem">{iconProvider(contact)}
                {contacts[contact] >= 1
                ?
                <div className='multipleContacts'>
                  {[...Array(contacts[contact])].map((i, index) => <span key={`random-${index}`}>{window.btoa(Math.random().toString()).substring(5,25)}</span>)}
                </div>
                :
                <span style={{ filter: 'none'}}>Not Provided</span>
                }
              </div>
              }
          </div>

        ))}
    </MetadataDiv>
  )
}

export default Contact