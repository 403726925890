import styled from 'styled-components';

export const EmailDiv = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    margin: 40px auto;
    width: 100%;

    & input, button{
        width: 261px;
        height: 38px;
    }

    & input{

        &:placeholder{
            font-size: 16px;
            color: #6C757D;
        }
    }

    & button{
        &:hover{
            background-color: blue !important;
        }
    }

    & div.errorMsg{
        margin: 15px;
        color: red;
        font-size: 19px;
        font-family: 'Nunito';
    }

    & div.spinner-border{
        width: 25px;
        height: 25px;
    }

`