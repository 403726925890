import styled from "styled-components";

export const TitleDiv = styled.section`
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 50px;
    font-family: Nunito;
    padding: 0;

    & svg{
        margin: 0 20px 20px 0;
    }

    @media only screen and (max-width: 800px){
        text-align: center;
    }

    & div.title{
        display: flex;
        align-items: center;

        & div.name, a{
            margin: 20px;
        }

        & div.name{
            font-size: 40px;
            font-weight: 500;
            color: #000000;
        }

        & div.icon{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background-color: #E9ECEF;
            box-shadow: 0 4px 4px 0 #00000025;

            & img{
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
        }

        & a{
            width: 172px;
            height: 38px;
            background-color: #DAE6F8;
            border-radius: 20px;
            text-align: center;
            color: #017DFC;
            text-decoration: none;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-weight: 400;
            font-size: 20px;

            &:hover {
                box-shadow: 0px 0px 10px 0px rgb(20 20 20 / 30%);
            }

            & svg{
                margin: 0 30px 0 10px;
            }
        }
        
        @media only screen and (max-width: 800px){
            & div.name{
                margin: 20px 10px;
                font-size: 25px;
            }
            & a{
                margin: 20px 0 20px auto;
                font-size: 17px;
                width: 152px;
            }
        }
    }

    & p{
        font-weight: 400;
        font-size: 20px;
        margin: 0;
        color: #000000;
    }
`