import styled from 'styled-components';

export const TechStackDiv = styled.div`
    font-size: 18px;
    color: #000000;
    font-family: Nunito;
    min-height: 700px;
    padding-bottom: 30px;
    border: 1px solid #dee2e6;
    border-top: 0;

    & div.catDiv{
        border-top: 1px solid #dee2e6;

        & div.name{
            background: #F4F2F2;
            padding: 14px 19px;
        }

        & div.tech{
            gap: 0 19px;
            padding: 0 14px 30px;
            display: grid;
            grid-template-columns: 90%;
            grid-template-rows: auto;

            @media (max-width: 992px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }

            & div.techDetails{
                display: flex;
                align-items: center;
                margin: 20px 0;

                & img, div{
                    margin-right: 14px;
                }

                & img{
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                }

                & div{
                    font-size: 18px;
                    color: #000000;
                }

                & span {
                    font-size: 14px;
                    color: #5F5F5F;
                }
            }
        }

        &:first-child{
            border-top: 0;
        }
    }

    & div.notFound{padding: 15px}
`
export const MetadataDiv = styled.div`
    border: 1px solid #DEE2E6;
    padding: 10px 14px 30px 14px;
    font-family: Nunito;
    border-top: 0;
    min-height: 700px;
    font-size: 18px;
    color: #000000;

    & div.title{
        font-weight: 700;
        margin: 30px 10px 10px 10px;
    }

    & div.content{
        background-color: rgba(238, 238, 238, 0.4);
        padding: 10px;
        min-height: 50px;
    }

    & div.title, div.content{
        font-size: 18px;
        color: #000000;
    }

    & div.contactItem{
        display: flex;
        align-items: baseline;

        & svg{
            margin-right: 15px;
        }

        & span{
            filter: blur(.5rem);
            color: #5cafe3;
        }

        & div.multipleContacts{
            display: flex;
            flex-direction: column;

            & span{
                margin: 5px 0;
            }
        }
    }

    & div.notFound{padding: 5px}
`