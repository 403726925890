import axios from '../../../services/commonHttp';

export const getTechnology = async (url:string) => axios({
        // method: 'get', url: `http://webtech-dev.boolkey.net/api${url}`, headers: { 'Content-Type': 'application/json' },
        method: 'get', url, headers: { 'Content-Type': 'application/json' },
    })

// const REST_API_URL = 'http://webtech-dev.boolkey.net/api'
// export const getTechnology = async (url:string) => fetch(REST_API_URL+url,
//     {
//         method: 'GET',
//         mode: 'cors',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         credentials: 'same-origin',
//     })