import { MetadataDiv } from "./styled-tabs";

const Metadata = ({title, description, keywords}: any) => {
  console.log(title, description, keywords)
  return (
    <MetadataDiv>
        {(!title && !description && !keywords)
        ?
        <div className='notFound'>Metadata is not found for this website</div>
        :
        [{title: 'Title' , content:title}, {title: 'Description' , content:description}, {title: 'Keywords' , content:keywords}].map(({title, content}, index) => (
            <div key={`${title}-${index}`}>
                <div className="title">{title}</div>
                <div className="content">
                  {content ? (Array.isArray(content) ? content.toString().replaceAll(',', ', ') : content) : 'Not Found'}
                </div>
            </div>
        ))
        }
    </MetadataDiv>
  )
}

export default Metadata