import { Footer } from '../../components/footer';
import DetailedWebsiteProfilesFeaturePage from '../../components/ProductFeature/DetailedWebsiteProfilesFeaturePage';

const DetailedWebsiteProfilesFeaturePageView = () => {
    return (
        <div id='wrapper'>
            <DetailedWebsiteProfilesFeaturePage />
            <Footer />
        </div>  
    )
}

export default DetailedWebsiteProfilesFeaturePageView;